import * as mqtt from 'mqtt';
import { all, takeEvery, call, put, fork, take, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import actions from './actions';
import authAction from '../auth/actions';
import corpAcctActions from '../corporateAccount/actions';
import * as _ from 'lodash';

const hostMqtt1 = process.env.REACT_APP_MQTT_HOST1;
const hostMqtt2 = process.env.REACT_APP_MQTT_HOST2;
const protocol = process.env.REACT_APP_MQTT_PROTOCOL;
const port = process.env.REACT_APP_MQTT_PORT;
const username = process.env.REACT_APP_MQTT_USER;
const password = process.env.REACT_APP_MQTT_PASS;

const { messageArrived } = corpAcctActions;
const url = [
  {
    protocol,
    host: hostMqtt1,
    port,
  },
  {
    protocol,
    host: hostMqtt2,
    port,
  },
];
const options = {
  clientId: '',
  clean: true,
  keepalive: 60,
  connectTimeout: 4000,
  rejectUnauthorized: false,
  username,
  password,
  servers: url,
};
let client;

export function* connectMQTT() {
  yield takeEvery('CONNECT', function* () {
    console.log('START MQTT');
    const state = yield select();
    const userInfo = state.Auth.identity;
    console.log('CHECK OPTIONS 1', options);
    options.clientId = 'constellation_ui' + Math.random().toString(16).substr(2, 8);
    const topicLogin = `/${process.env.REACT_APP_ENVIRONMENT}/const/sessions/${userInfo.username}`;
    try {
      client = mqtt.connect(options);

      client.on('connect', () => {
        client.subscribe([topicLogin]);
        console.log('MQTT CONNECT SUCCESS');
      });

      client.on('error', (error) => {
        console.log('MQTT CONNECT ERROR', error);
      });

      client.end(true, {}, () => {
        console.log('MQTT RECONNECTING');
        client.reconnect();
      });

      const eventResult = yield call(createMQTTEventHandler, client);

      while (true) {
        try {
          // An error from socketChannel will cause the saga jump to the catch block
          const payload = yield take(eventResult);
          console.log('CHECK MQTT RESULT', payload);

          if (payload && payload.token) {
            yield put({
              type: authAction.LOGIN_VALIDATOR,
              token: payload.token,
            });

            yield localStorage.setItem('mqttToken', payload.token);
          }
        } catch (err) {
          console.error('socket error:', err)
        }
      }

    } catch (e) {
      console.log('MQTT CONNECT FAILED', e);

      yield put({
        type: actions.CONNECT_FAILED
      });
    }
  });
}

function createMQTTEventHandler(client) {
  return eventChannel(emitter => {
    client.on('message', (topic, message) => {
      console.log('CHECK MQTT TOPIC', topic);
      const newPayload = JSON.parse(message.toString());
      console.log('CHECK MQTT PAYLOAD', newPayload);
      emitter(newPayload);
    });
    return () => false;
  });
}

export function* connectToMqtt() {
  yield takeEvery('CONNECT_MQTT', function* ({ payload }) {
    console.log('CHECK MQTT CLIENT 1', client);
    console.log('CHECK DOWNLOAD MQTT PAYLOAD', payload);
    //const options = state.Auth.mqttOptions;
    // options.clientId = 'constellation_ui' + Math.random().toString(16).substr(2, 8);
    options.clientId = '';
    console.log('CHECK OPTIONS 2', options);

    try {
      console.log('CHECK MQTT CONNECTING');
      client = mqtt.connect(options);

      client.on('connect', () => {
        console.log('CHECK MQTT ON CONNECT', payload.topic);
        client.subscribe([payload.topic]);
        console.log('CHECK MQTT ON SUBSCRIBED');
      });

      client.on('error', (error) => {
        console.log('CHECK MQTT ERROR');
      });

      client.end(true, {}, () => {
        console.log('CHECK MQTT RECONNECTING');
        client.reconnect();
        console.log('CHECK MQTT RECONNECTED');
      });

      console.log('CHECK MQTT CLIENT 2', client);
      const eventResult = yield call(createMQTTEventHandlerNew, client);
      console.log('CHECK MQTT EVENT RESULT', eventResult);

      while (true) {
        try {
          const result = yield take(eventResult)
          console.log('CHECK MQTT REQUESTED PAYLOAD', payload);
          console.log('CHECK MQTT RECEIVED RESULT', result);
          const state = yield select();
          if (result.type) {
            if (payload.module === 'transaction-download') {
              if (result.payload.status !== 'FAILED') {
                yield put({
                  type: corpAcctActions.DOWNLOADED_REPORT,
                  data: result.payload.fileName,
                });
              } else {
                yield put({
                  type: corpAcctActions.DOWNLOAD_REPORT_FAILED,
                  payload: 'No Transaction Found!',
                });
              }
            } else if (payload.module === 'journal_entry/transaction/download') {
              console.log('START JOURNAL DOWNLOAD MQTT');
              const currentData = state.CorporateAccount.excelDownloadList;
              // console.log('currentData', currentData);
              const updatedList = _.reject(currentData, { Id: result.payload.Id });
              console.log('updatedList', updatedList);
              // console.log('RESULT_PAYLOAD', result.payload);
              if (result.payload.status !== 'No Transaction Found!') {
                const newList = _.union([result.payload], updatedList);
                console.log('newList', updatedList);
                yield put({
                  type: corpAcctActions.FETCHED_EXCEL_DOWNLOAD_LIST,
                  payload: newList,
                });
              } else {
                yield put({
                  type: corpAcctActions.FETCHED_EXCEL_DOWNLOAD_LIST,
                  payload: updatedList,
                });
                yield put({
                  type: corpAcctActions.DOWNLOAD_STATUS,
                  payload: 'No Transaction Found!',
                });
              }
            }
          }
        } catch (err) {
          console.error('socket error:', err)
        }
      }
    } catch (e) {
      console.log('CHECK MQTT ERROR', e);
      // console.log('ERROR mESSAGE', e.message)
      yield put({
        type: actions.CONNECT_FAILED
      });
    }
  });
}

function createMQTTEventHandlerNew(client) {
  return eventChannel(emitter => {
    client.on('message', (topic, message) => {
      const newPayload = JSON.parse(message.toString());
      // console.log('CHECK MQTT MESSAGE RECEIVED 1', newPayload);
      emitter(messageArrived(newPayload));
    });
    return () => false;
  });
}

export default function* rootSaga() {
  yield all([
    fork(connectMQTT),
    fork(connectToMqtt)
  ]);
}

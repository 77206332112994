import * as cookie from 'js-cookie';
import moment from 'moment-timezone';
import { createBrowserHistory } from 'history';
import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  getToken,
  getKeyGen,
  getPassport,
  clearToken,
  getForbidToken,
  clearForbidToken,
  getModules,
  getIdentity,
  clearModules,
  clearIdentity,
  clearStores,
  clearTopic,
  getEnv,
  clearEnv,
  clearMqttToken,
  getMqttToken,
  getCustomAuth,
  clearCustomAuth,
  getCustomModule,
  clearCustomModule,
  clearRoles,
  clearMerchantList,
  clearSelectedMerchant,
  clearSelectedMerchantName,
  getRequestFormats,
  clearRequestFormats,
  getResetData,
  clearResetData,
  getCountry,
  getKYCProviders,
  clearCountry,
  clearKYCProviders
} from '@iso/lib/helpers/utility';
import actions from './actions';
import mqttAction from '../mqtt/actions';
import corpActions from '../corporateAccount/actions';
import { get, fetchWithForbidden, PUT, post } from '@iso/lib/helpers/resApiRequestor';
import { pushDataToApp } from '@iso/lib/helpers/mobileAppCommKit';
import { encryptRequest } from '@iso/lib/helpers/encryption';
import { generateRandomString } from '@iso/lib/helpers/stringUtils';

const history = createBrowserHistory();
const apiUrl = process.env.REACT_APP_API_URL;
const systemId = process.env.REACT_APP_SYSTEM_ID;
let headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    try {
      yield put({
        type: actions.KICKED_OUT_CLEAR,
      });

      yield put({
        type: actions.CLEAR_ERROR,
      });

      yield put({
        type: actions.SET_LOADING,
        loading: true,
      });

      const apiResult = yield login(payload);
      console.log('CHECK LOGIN RESULT 1', apiResult);
      if (apiResult) {
        const result = apiResult.data ? apiResult.data : apiResult;
        console.log('CHECK LOGIN RESULT 2', result);
        if (result.success) {
          // if(!result.data.isFirst || !result.data.isPasswordExp ){
          const authValue = result.data.user.role.authorizations;
          console.log('CHECK LOGIN RESULT 3', authValue);
          let formattedAuthorization = [];
          let formattedModuleId = {};
          for (let i = 0; i < authValue.length; i++) {
            const element = authValue[i];
            if (element.privilege.read) {
              formattedModuleId[element.moduleId] = element;
            }
          }
          const allPrivilegesFalse = Object.values(formattedModuleId).every(element => !element.privilege);
          if (!allPrivilegesFalse) {
            formattedAuthorization.push(formattedModuleId);
          }
          console.log('CHECK LOGIN RESULT 4', formattedAuthorization);
          // const apiResultRequestFormats = yield fetchRequestFormats(result.data.token);
          // const resultRequestFormats = apiResultRequestFormats.data;
          const moduleResult = yield fetchModules(result.data.token);
          console.log('CHECK LOGIN RESULT 5', moduleResult);
          const modResult = moduleResult.data;
          if (modResult.success) {
            let formattedModule = [];
            let filteredModule = [];
            let filteredCoreModule = [];
            let formatteElement = {};
            let moduleData = modResult.data.result
            for (let i = 0; i < moduleData.length; i++) {
              let modulesData = moduleData[i].submodules;
              for (let i = 0; i < modulesData.length; i++) {
                const element = modulesData[i];
                if (modulesData && Array.isArray(modulesData)) {
                  if (formattedAuthorization[0][element.Id] && formattedAuthorization[0][element.Id] !== undefined) {
                    const formattedSubmodules = [];
                    modulesData.forEach(smod => {
                      if (formattedAuthorization[0][smod.Id] && formattedAuthorization[0][smod.Id] !== undefined) {
                        formattedSubmodules.push(smod);
                      }
                    });
                    const updatedModules = {
                      ...element,
                      submodules: formattedSubmodules,
                    }

                    filteredModule.push(updatedModules);
                    formatteElement[element.alias] = updatedModules;
                  }
                }
              }
            }

            formattedModule.push(formatteElement);
            console.log('CHECK LOGIN RESULT 6', formattedModule);

            const countryResult = yield fetchCountry(result.data.token);
            console.log('CHECK LOGIN RESULT 7', countryResult.data.data);

            const kycProvidersResult = yield fetchKycProviders();
            console.log('CHECK LOGIN RESULT 8', kycProvidersResult.data.data);

            formattedModule.forEach(authItem => {
              Object.keys(authItem).forEach(moduleId => {
                const matchingModule = modResult.data.result.find(moduleItem => moduleItem.Id === authItem[moduleId].parentId);
                if (matchingModule) {
                  const existingIndex = filteredCoreModule.findIndex(item => item.Id === matchingModule.Id);
                  if (existingIndex !== -1) {
                    filteredCoreModule[existingIndex] = matchingModule;
                  } else {
                    filteredCoreModule.push(matchingModule);
                  }
                }
              });
            });

            yield put({
              type: actions.SUBMODULES,
              //payload: modResult.data.result,
              payload: filteredModule,
            });

            yield put({
              type: corpActions.RESET_CORP_WALLETS_DETAILS,
            });

            cookie.set('token', result.data.token);

            yield put({
              type: actions.LOGIN_SUCCESS,
              token: result.data.token,
              identity: JSON.stringify(result.data.user),
              keyGen: result.data.key,
              passport: result.data.passport,
              resetData: result.data.resetData && result.data.resetData !== 'undefined' ? JSON.stringify(result.data.resetData) : '',
              // modules: JSON.stringify(filteredModule),
              // modules: JSON.stringify(modResult.data.result),
              modules: JSON.stringify(filteredCoreModule),
              customAuthorizations: JSON.stringify(formattedAuthorization[0]),
              customModules: JSON.stringify(formattedModule[0]),
              // requestFormats: !resultRequestFormats.data ? "" : resultRequestFormats.data,
              profile: 'Profile',
              env: result.data.env,
              country: JSON.stringify(countryResult.data.data),
              kycProviders: JSON.stringify(kycProvidersResult.data.data)
            });

            yield put({
              type: mqttAction.CONNECT,
            });

            yield put({
              type: actions.SET_LOADING,
              loading: false,
            });
          }
          // } else{

          // }
        } else {
          if (result.data && (result.data.isFirst || result.data.isPasswordExp)) {
            yield put({
              type: actions.NEW_USER,
              tokenId: result.data.user.token,
              isFirst: result.data.isFirst,
              isPasswordExp: result.data.isPasswordExp
            });
          } else if (result && result.message === 'Unauthorized') {
            yield put({
              type: actions.LOGIN_ERROR,
              error: 'INVALID_AUTH',
            });
          } else if (result && result.message === 'Invalid OTP.') {
            yield put({
              type: actions.LOGIN_ERROR,
              error: 'INVALID_OTP',
            });
          } else if (result && result.message === 'Expired OTP.') {
            yield put({
              type: actions.LOGIN_ERROR,
              error: 'EXPIRED_OTP',
            });
          } else if (result && result.message === 'Account not verified') {
            yield put({
              type: actions.LOGIN_ERROR,
              error: 'NOT_VERIFIED',
            });
          } else if (result && result.message === 'Account has been locked.') {
            yield put({
              type: actions.LOGIN_ERROR,
              error: 'ACCOUNT_LOCKED',
            });
          } else if (result && result.message === 'Token is invalid.') {
            yield put({
              type: actions.LOGIN_ERROR,
              error: 'INVALID_TOKEN',
            });
          } else if (result && result.message === 'Expired Token.') {
            yield put({
              type: actions.LOGIN_ERROR,
              error: 'EXPIRED_TOKEN',
            });
          } else {
            yield put({
              type: actions.LOGIN_ERROR,
              error: 'Login Error',
            });
          }

          yield put({
            type: actions.SET_LOADING,
            loading: false,
          });
        }
      } else {
        console.log('CHECK ERRORA', apiResult);
        const result = apiResult.data ? apiResult.data : apiResult;
        yield put({
          type: actions.LOGIN_ERROR,
          error: result && result.message
            ? result.message === 'Invalid OTP.' ? 'INVALID_OTP'
              : result.message === 'Expired OTP.' ? 'EXPIRED_OTP'
                : 'INVALID_AUTH'
            : 'INVALID_AUTH',
        });
        yield put({
          type: actions.SET_LOADING,
          loading: false,
        });
      }

    } catch (e) {
      console.log('CHECK LOGIN ERROR B', e);
      yield put({
        type: actions.LOGIN_ERROR,
        error: 'Login Error',
      });
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
    }
  });
}

export function* getResetPassUrl() {
  yield takeEvery('FETCH_RESET_PASSWORD_URL', function* () {
    try {
      const apiResult = yield fetchResetPassUrl();
      const result = apiResult.data;

      yield put({
        type: actions.FETCHING_RESET_PASSWORD_URL,
      });

      yield put({
        type: actions.FETCHED_RESET_PASSWORD_URL,
        resetPassUrl: result,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_RESET_PASSWORD_URL_FAILED,
      });
    }
  });
}

export function* forgotPassword() {
  yield takeEvery('FORGOT_PASSWORD', function* ({ emailData }) {
    yield put({
      type: actions.CLEAR_ERROR,
    });
    yield put({
      type: actions.SET_LOADING,
      loading: true,
    });
    // const token = false;
    try {
      const genereateUniqueCodeResp = yield generateUniqueCodeApi({
        key: `ASTERISM0000${moment().tz('Asia/Manila').format('YYYYMMDD')}${generateRandomString(7)}`,
      });
      console.log('CHECK GENERATE CODE RESPONSE', genereateUniqueCodeResp);

      const encryptedData = encryptRequest(emailData, genereateUniqueCodeResp.data.passport);
      const data = {
        key: genereateUniqueCodeResp.data.key,
        data: encryptedData
      };

      const apiResult = yield forgotPasswordReq(data);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FORGOT_ERROR,
          error: null,
        });
        yield put({
          type: actions.SET_LOADING,
          loading: false,
        });
        yield put({
          type: actions.FORGOT_PASSWORD_DONE,
          success: true,
          passport: genereateUniqueCodeResp.data.passport,
          keyGen: genereateUniqueCodeResp.data.key,
        });
      } else {
        yield put({
          type: actions.FORGOT_PASSWORD_DONE,
          success: false,
        });
        yield put({
          type: actions.FORGOT_ERROR,
          error: result.data,
        });
        yield put({
          type: actions.SET_LOADING,
          loading: false,
        });
      }
    } catch (e) {
      console.log(e)
      yield put({
        type: actions.SET_LOADING,
        loading: false,
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('idToken', payload.token);
    yield localStorage.setItem('modules', payload.modules);
    yield localStorage.setItem('identity', payload.identity);
    yield localStorage.setItem('ASTERISM0000', payload.keyGen);
    yield localStorage.setItem('ASTERISM0001', payload.passport);
    // yield localStorage.setItem('roles', payload.roles);
    yield localStorage.setItem('env', payload.env);
    yield localStorage.setItem('customAuthorizations', payload.customAuthorizations);
    yield localStorage.setItem('customModules', payload.customModules);
    yield localStorage.setItem('requestFormats', payload.requestFormats);
    yield localStorage.setItem('resetData', payload.resetData);
    yield localStorage.setItem('country', payload.country);
    yield localStorage.setItem('kycProviders', payload.kycProviders);
    pushDataToApp('idToken', payload.token);
    pushDataToApp('modules', payload.modules);
    pushDataToApp('identity', payload.identity);
    pushDataToApp('customAuthorizations', payload.customAuthorizations);
    pushDataToApp('customModules', payload.customModules);
    pushDataToApp('requestFormats', payload.requestFormats);
    pushDataToApp('resetData', payload.resetData);
    pushDataToApp('keyGen', payload.keyGen);
    pushDataToApp('passport', payload.passport);
    pushDataToApp('country', payload.country);
    pushDataToApp('kycProviders', payload.kycProviders);
    // pushDataToApp('roles', payload.roles);
    yield put({
      type: actions.RESET_SECOND_TIMER,
      secondTimer: 1800,
    });
  });
}

export function* subModuleLoad() {
  yield takeEvery(actions.SUBMODULES, function* (data) {
    yield localStorage.setItem('modules', typeof data.payload === 'string' ? JSON.parse(data.payload) : data.payload);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* (payload) {
    yield call(logoutPassport, payload);
    yield clearToken();
    yield clearModules();
    yield clearIdentity();
    yield clearMqttToken();
    yield clearCustomAuth();
    yield clearCustomModule();
    yield clearForbidToken();
    yield clearEnv();
    yield clearCustomAuth();
    yield clearCustomModule();
    yield clearResetData();
    yield clearRequestFormats();
    yield clearCountry();
    yield clearKYCProviders();
    // yield clearRoles();

    yield put({
      type: actions.RESET_SECOND_TIMER,
      secondTimer: 0,
    });
    history.push('/');
  });
}

function logoutPassport(data) {
  console.log('LOGOUT PASSPORT: ', data)
  return fetchWithForbidden(`${apiUrl}authenticate/logout`, {
    headers: {
      ...headers,
    },
    method: 'POST',
    data,
  });
}

export function* kickedOutClear() {
  yield takeEvery(actions.KICKED_OUT_CLEAR, function* () {
    yield clearForbidToken();
  });
}
export function* checkAuthorization() {
  console.log('CHECK AUTHORIZATION')

  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const forbidToken = getForbidToken().get('forbidToken');
    const token = getToken().get('idToken');
    const keyGen = getKeyGen().get('keyGen');
    const passport = getPassport().get('passport');
    const modules = getModules().get('modules');
    const identity = getIdentity().get('identity');
    const env = getEnv().get('env');
    const mqttToken = getMqttToken().get('mqttToken');
    const customAuthorizations = getCustomAuth().get('customAuthorizations');
    const customModules = getCustomModule().get('customModules');
    // const roles = getRoles().get('roles');
    const requestFormats = getRequestFormats().get('requestFormats');
    const resetData = getResetData().get('resetData');
    const country = getCountry().get('country');
    const kycProviders = getKYCProviders().get('kycProviders');

    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        identity,
        keyGen,
        passport,
        modules,
        resetData,
        profile: 'Profile',
        env,
        customAuthorizations,
        customModules,
        // roles,
        requestFormats,
        country,
        kycProviders,
      });

      yield put({
        type: actions.LOGIN_VALIDATOR,
        token: mqttToken,
      });

      yield put({
        type: mqttAction.CONNECT,
      });
    }

    if (forbidToken) {
      yield put({
        type: actions.KICKED_OUT,
      });
      yield put({
        type: actions.LOGOUT,
        payload: keyGen
      });
    }
  });
}

export function* changePassword() {
  yield takeEvery('CHANGE_PASSWORD', function* (payload) {
    try {
      yield put({
        type: actions.CHANGING_PASSWORD,
      });

      console.log('CHANGE_PASSWORD_SAGA PAYLOAD', payload.payload);
      const apiResult = yield changePasswordDone(payload.payload);
      console.log('CHANGE_PASSWORD API RESULT', apiResult);

      if (apiResult.data.success === true) {
        yield put({
          type: actions.CHANGED_PASSWORD,
          message: apiResult.data.message,
        });
      } else {
        yield put({
          type: actions.CHANGED_PASSWORD_FAILED,
          payload: apiResult.data.message
        });
      }
    } catch (error) {
      yield put({
        type: actions.CHANGED_PASSWORD_FAILED,
        messsage: 'Failed to change password.',
      });
    }
  });
}


export function* fetchGlyphInfo() {
  yield takeEvery('FETCH_GLYPH_INFO', function* () {
    try {
      yield put({
        type: actions.FETCHING_GLYPH_INFO,
      });

      const apiResult = yield fetchGlyphInfoUrl();
      const result = apiResult.data;

      if (result.success) {
        yield put({
          type: actions.FETCHED_GLYPH_INFO_SUCCESS,
          glyphInfo: result,
        });
      } else {
        yield put({
          type: actions.FETCHED_GLYPH_INFO_FAILED,
          message: 'Failed to fetch Glyph Wallet'
        });
      }

    } catch (error) {
      yield put({
        type: actions.FETCHED_GLYPH_INFO_FAILED,
        message: 'Failed to fetch Glyph Wallet'

      });
    }
  });
}

function login(data) {
  return fetchWithForbidden(`${apiUrl}authenticate`, {
    headers: {
      ...headers,
    },
    method: 'POST',
    data,
    //withCredentials: true,
  });
}

function fetchModules(token) {
  return get(`system/${systemId}/modules`, null, null, null, token);
}

function fetchCountry(token) {
  return get(`fetch/country`, null, null, null, token);
}

function fetchKycProviders() {
  return get(`fetch/kyc_providers`, null, null, null);
}

// function fetchAllRoles(token) {
//   return get(`roles/fetch/store_roles`, null, null, null, token);
// }

function forgotPasswordReq(emailData) {
  // return get(`resettokens/get_id_by_email/${email}`);

  console.log("CHECK EMAIL REQ DATA: ", emailData);
  return post(`resettokens/get_id_by_email`, emailData);
}

function fetchResetPassUrl() {
  return get(`authenticate/get_url/reset_password`);
}
function changePasswordDone(payload) {
  const data = {
    key: payload.key,
    data: payload.data
  };

  return PUT(`authenticate/edit_password/${payload.id}`, data);
}

export function* generateAuthOtp() {
  yield takeEvery('GENERATE_LOGIN_OTP', function* ({ payload }) {
    try {
      yield put({
        type: actions.GENERATING_LOGIN_OTP,
      });

      const genereateUniqueCodeResp = yield generateUniqueCodeApi({
        key: `ASTERISM0000${moment().tz('Asia/Manila').format('YYYYMMDD')}${generateRandomString(7)}`,
      });
      console.log('CHECK GENERATE CODE RESPONSE', genereateUniqueCodeResp);

      const encryptedData = encryptRequest(payload, genereateUniqueCodeResp.data.passport);
      const data = {
        key: genereateUniqueCodeResp.data.key,
        data: encryptedData
      };

      console.log('GENERATE OTP START');
      const apiResult = yield generateOtp(data);
      console.log('CHECK GENERATE OTP RES', apiResult);
      const result = apiResult.data;

      if (result.success) {
        yield put({
          type: actions.GENERATED_LOGIN_OTP,
          message: result.message,
          expiration: result.expiration,
          passport: genereateUniqueCodeResp.data.passport,
          keyGen: genereateUniqueCodeResp.data.key,
        });
      } else {
        yield put({
          type: actions.GENERATE_LOGIN_OTP_FAILED,
          message: result.message,
          error:
            result.message === 'Invalid Authentication.'
              ? 'INVALID_AUTH'
              : result.message === 'Account has been locked.'
                ? 'ACCOUNT_LOCKED'
                : result.message === 'Invalid OTP.'
                  ? 'INVALID_OTP'
                  : result.message === 'Expired OTP.'
                    ? 'EXPIRED_OTP'
                    : null
        });
      }
    } catch (e) {
      yield put({
        type: actions.GENERATE_LOGIN_OTP_FAILED,
        message: 'Invalid Account.',
        error: null,
      });
    }
  });
}

export function* resendLoginOtp() {
  yield takeEvery('RESEND_LOGIN_OTP', function* ({ payload }) {
    try {
      yield put({
        type: actions.RESENDING_LOGIN_OTP,
      });

      const genereateUniqueCodeResp = yield generateUniqueCodeApi({
        key: `ASTERISM0000${moment().tz('Asia/Manila').format('YYYYMMDD')}${generateRandomString(7)}`,
      });
      console.log('CHECK GENERATE CODE RESPONSE', genereateUniqueCodeResp);

      const encryptedData = encryptRequest(payload, genereateUniqueCodeResp.data.passport);
      const data = {
        key: genereateUniqueCodeResp.data.key,
        data: encryptedData
      };

      const apiResult = yield generateOtp(data);
      console.log('CHECK RESEND OTP RES', apiResult);
      const result = apiResult.data;

      if (result.success) {
        yield put({
          type: actions.RESEND_LOGIN_OTP_SUCCESS,
          message: result.message,
          expiration: result.expiration,
          passport: genereateUniqueCodeResp.data.passport,
          keyGen: genereateUniqueCodeResp.data.key,
        });
      } else {
        yield put({
          type: actions.RESEND_LOGIN_OTP_FAILED,
          message: result.message,
        });
      }
    } catch (e) {
      yield put({
        type: actions.RESEND_LOGIN_OTP_FAILED,
        message: 'Invalid Account.',
      });
    }
  });
}

export function* forgotPasswordReset() {
  yield takeEvery('RESET_FORGOT_PASSWORD', function* ({ payload }) {
    try {
      yield put({
        type: actions.RESETTING_FORGOT_PASSWORD,
      });

      console.log('CHECK RESET PASSWORD PAYLOAD', payload)
      const genereateUniqueCodeResp = yield generateUniqueCodeApi({
        key: `ASTERISM0000${moment().tz('Asia/Manila').format('YYYYMMDD')}${generateRandomString(7)}`,
      });
      console.log('CHECK GENERATE CODE RESPONSE', genereateUniqueCodeResp);

      const encryptedData = encryptRequest(payload, genereateUniqueCodeResp.data.passport);
      const data = {
        key: genereateUniqueCodeResp.data.key,
        data: encryptedData
      };

      const apiResult = yield forgotPasswordResetRequest(data);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.RESET_FORGOT_PASSWORD_SUCCESS,
          result: result ? result.data : null,
        });
      } else {
        yield put({
          type: actions.RESET_FORGOT_PASSWORD_FAILED,
          message: result ? result.message : null
        });
      }
    } catch (error) {
      yield put({
        type: actions.RESET_FORGOT_PASSWORD_FAILED,
        message: 'Failed to reset password.'
      });
    }
  });
}

export function* resetTokenData() {
  yield takeEvery('RESET_TOKEN_DATA', function* (payload) {
    try {
      yield put({
        type: actions.RESETTING_TOKEN_DATA,
      });
      const apiResult = yield resettingTokenData(payload.payload);
      const result = apiResult.data;
      console.log('RESET TOKEN DATA RESULT', result);
      if (result.success) {
        yield put({
          type: actions.RESET_TOKEN_DATA_SUCCESS,
          result: result ? result.data : null,
        });
      } else {
        yield put({
          type: actions.RESET_TOKEN_DATA_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.RESET_TOKEN_DATA_FAILED,
      });
    }
  });
}

function generateOtp(data) {
  return fetchWithForbidden(`${apiUrl}authenticate/otp/generate`, {
    headers: {
      ...headers,
    },
    method: 'POST',
    data,
    //withCredentials: true,
  });
}

function fetchGlyphInfoUrl() {
  return get(`get_glyph`);
}

const generateUniqueCodeApi = (payload) => {
  return post(`authenticate/generate/key`, payload);
};

function forgotPasswordResetRequest(data) {
  return post(`authenticate/forgot_password_reset`, data);
}

function resettingTokenData(id) {
  return get(`resettokens/get_data_by_token/${id}`);
}

export function* logoutNoRedirect() {
  yield takeEvery(actions.LOGOUT_NO_REDIRECT, function* (payload) {
    yield call(logoutPassport, payload);
    yield clearToken();
    yield clearModules();
    yield clearIdentity();
    yield clearStores();
    yield clearTopic();
    yield clearEnv();
    yield clearMqttToken();
    yield clearCustomAuth();
    yield clearCustomModule();
    yield clearForbidToken();
    yield clearRoles();
    yield clearRequestFormats();
    yield clearMerchantList();
    yield clearSelectedMerchant();
    yield clearSelectedMerchantName();
    yield clearResetData();
    yield clearCountry();
    yield clearKYCProviders();
    yield put({
      type: actions.RESET_SECOND_TIMER,
      secondTimer: 0,
    });
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(forgotPassword),
    fork(loginSuccess),
    fork(subModuleLoad),
    fork(loginError),
    fork(logout),
    fork(kickedOutClear),
    fork(getResetPassUrl),
    fork(changePassword),
    fork(generateAuthOtp),
    fork(resendLoginOtp),
    fork(logoutNoRedirect),
    fork(fetchGlyphInfo),
    fork(forgotPasswordReset),
    fork(resetTokenData)
  ]);
}


import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import corporateAccountSaga from '@iso/redux/corporateAccount/saga';
import mqttSaga from '@iso/redux/mqtt/saga';
import settingsSaga from '@iso/redux/settings/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    corporateAccountSaga(),
    mqttSaga(),
    settingsSaga()
  ]);
}

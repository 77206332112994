const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGOUT_NO_REDIRECT: 'LOGOUT_NO_REDIRECT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  FORGOT_ERROR: 'FORGOT_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
  // SET_MQTT_OPTS: 'SET_MQTT_OPTS',
  SUBMODULES: 'SUBMODULES',
  SET_LOADING: 'SET_LOADING',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_DONE: 'FORGOT_PASSWORD_DONE',
  RESET_SECOND_TIMER: 'RESET_SECOND_TIMER',
  ON_RESET_TIMER: 'ON_RESET_TIMER',
  LOGIN_VALIDATOR: 'LOGIN_VALIDATOR',
  FORBIDDEN_REQUEST: 'FORBIDDEN_REQUEST',
  KICKED_OUT: 'KICKED_OUT',
  KICKED_OUT_CLEAR: 'KICKED_OUT_CLEAR',
  // GET REDIRECT URL
  FETCH_REDIRECT_URL: 'FETCH_REDIRECT_URL',
  // GET RESET PASSWORD URL
  FETCH_RESET_PASSWORD_URL: 'FETCH_RESET_PASSWORD_URL',
  FETCHING_RESET_PASSWORD_URL: 'FETCHING_RESET_PASSWORD_URL',
  FETCHED_RESET_PASSWORD_URL: 'FETCHED_RESET_PASSWORD_URL',
  FETCH_RESET_PASSWORD_URL_FAILED: 'FETCH_RESET_PASSWORD_URL_FAILED',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD', // trigger
  CHANGING_PASSWORD: 'CHANGING_PASSWORD', // loading
  CHANGED_PASSWORD: 'CHANGED_PASSWORD', // success
  CHANGED_PASSWORD_FAILED: 'CHANGED_PASSWORD_FAILED', //failed
  RESET_CHANGE_PASSWORD: 'RESET_CHANGE_PASSWORD', //reset 

  GENERATE_LOGIN_OTP: 'GENERATE_LOGIN_OTP',
  GENERATED_LOGIN_OTP: 'GENERATED_LOGIN_OTP',
  GENERATING_LOGIN_OTP: 'GENERATING_LOGIN_OTP',
  GENERATE_LOGIN_OTP_FAILED: 'GENERATE_LOGIN_OTP_FAILED',
  GENERATE_LOGIN_OTP_RESET: 'GENERATE_LOGIN_OTP_RESET',

  RESEND_LOGIN_OTP: 'RESEND_LOGIN_OTP',
  RESENDING_LOGIN_OTP: 'RESENDING_LOGIN_OTP',
  RESEND_LOGIN_OTP_SUCCESS: 'RESEND_LOGIN_OTP_SUCCESS',
  RESEND_LOGIN_OTP_FAILED: 'RESEND_LOGIN_OTP_FAILED',
  RESEND_LOGIN_OTP_RESET: 'RESEND_LOGIN_OTP_RESET',

  NEW_USER: 'NEW_USER',

  FETCH_GLYPH_INFO: 'FETCH_GLYPH_INFO',
  FETCHING_GLYPH_INFO: 'FETCHING_GLYPH_INFO',
  FETCHED_GLYPH_INFO_SUCCESS: 'FETCHED_GLYPH_INFO_SUCCESS',
  FETCHED_GLYPH_INFO_FAILED: 'FETCHED_GLYPH_INFO_FAILED',

  // NEW RESET PASSWORD
  RESET_FORGOT_PASSWORD: 'RESET_FORGOT_PASSWORD',
  RESETTING_FORGOT_PASSWORD: 'RESETTING_FORGOT_PASSWORD',
  RESET_FORGOT_PASSWORD_SUCCESS: 'RESET_FORGOT_PASSWORD_SUCCESS',
  RESET_FORGOT_PASSWORD_FAILED: 'RESET_FORGOT_PASSWORD_FAILED',

  // RESET PASSWORD
  RESET_TOKEN_DATA: 'RESET_TOKEN_DATA',
  RESETTING_TOKEN_DATA: 'RESETTING_TOKEN_DATA',
  RESET_TOKEN_DATA_SUCCESS: 'RESET_TOKEN_DATA_SUCCESS',
  RESET_TOKEN_DATA_FAILED: 'RESET_TOKEN_DATA_FAILED',
  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',

  resendLoginOtp: (payload) => ({
    type: actions.RESEND_LOGIN_OTP,
    payload: payload,
  }),
  resetResendLoginOtp: () => ({
    type: actions.RESEND_LOGIN_OTP_RESET,
  }),
  generateLoginOtp: (payload) => ({
    type: actions.GENERATE_LOGIN_OTP,
    payload: payload,
  }),
  resetGenerateLoginOtp: () => ({
    type: actions.GENERATE_LOGIN_OTP_RESET,
  }),
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (payload) => ({
    type: actions.LOGIN_REQUEST,
    payload: payload,
  }),
  logout: (payload) => ({
    type: actions.LOGOUT,
    payload: payload,
  }),
  logoutNoRedirect: () => ({
    type: actions.LOGOUT_NO_REDIRECT,
  }),
  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
  setSubmodules: (payload) => ({
    type: actions.SUBMODULES,
    modules: payload,
  }),
  forgotPassword: (emailData) => ({
    type: actions.FORGOT_PASSWORD,
    emailData,
  }),
  resetSecondTimer: (payload) => ({
    type: actions.RESET_SECOND_TIMER,
    secondTimer: payload,
  }),
  fetchRedirectUrl: () => ({
    type: actions.FETCH_REDIRECT_URL,
  }),
  fetchResetPassUrl: () => ({
    type: actions.FETCH_RESET_PASSWORD_URL,
  }),
  resetChangePassword: () => ({
    type: actions.RESET_CHANGE_PASSWORD,
  }),
  changePassword: (payload) => ({
    type: actions.CHANGE_PASSWORD,
    payload,
  }),

  fetchGlyphInfo: () => ({
    type: actions.FETCH_GLYPH_INFO
  }),

  // NEW RESET PASSWORD
  resetPassword: (payload) => ({
    type: actions.RESET_FORGOT_PASSWORD,
    payload,
  }),
  resetTokenData: (payload) => ({
    type: actions.RESET_TOKEN_DATA,
    payload,
  }),
  resetErrorMessageHandler: () => ({
    type: actions.RESET_ERROR_MESSAGE,
    payload: null,
    isError: false,
  }),
};
export default actions;

const initState = {
  connected: false,
};

export default function mqttReducer(state = initState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

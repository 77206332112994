const actions = {
  CONNECT: 'CONNECT',
  CONNECTING: 'CONNECTING',
  CONNECT_SUCCESS: 'CONNECT_SUCCESS',
  CONNECT_FAILED: 'CONNECT_FAILED',
  CONNECT_MQTT: 'CONNECT_MQTT',
  connectMqtt: () => ({ type: actions.CONNECT }),

  connectMqttForDl: (payload) => ({
    type: actions.CONNECT_MQTT,
    payload,
  }),
};
export default actions;

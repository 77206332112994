import actions from './actions';

const initState = {
    testData: null,
    fetchingSampleList: false,
    searchingLogs: false,
    searchResult: null,
    searchResultCount: 0,
    searchResultPage: 1,
    searchResultCollection: null,
    loadingMoreLogs: false,
    loadedMoreLogs: null,
    fetchedRole: null,
    fetchingRole: false,
    fetchingRolePermission: false,
    addingRole: false,
    addAuthRoleSuccess: false,
    addRoleMessage: null,
    openRolePermissionDialog: false,
    rolePermission: null,
    payloadCore: null,
    selectedId: null,
    statusData: true,
    authManagementRoleSaved: false,
    editingRoleAuthorization: false,

    // LOOKUPS
    lookupsData: null,
    fetchingLookupsData: false,
    lookupsMessage: null,
    lookupsUpdateMessage: null,
    //
    addingLookups: false,
    lookupsAdded: false,
    //
    deletingLookups: false,
    lookupsDeleted: false,
    //
    updatingLookups: false,
    lookupsUpdated: false,

    // RESPONSE CODE
    fetchingResponseCode: false,
    responseCodes: null,
    loadingMoreResponseCode: false,

    //
    addingResponseCode: false,
    addingResponseCodeSuccess: false,
    addingResponseCodeFailed: false,
    addingResponseCodeFailedMessage: null,
    addingResponseCodeError: false,
    //
    updatingResponseCode: false,
    updatingResponseCodeSuccess: false,
    updatingResponseCodeFailed: false,
    updatingResponseCodeFailedMessage: null,
    updatingResponseCodeError: false,

    //
    deletingResponseCode: false,
    responseCodesDeleted: false,

    // USER MANAGEMENT
    fetchingUsers: false,
    fetchedUsers: null,
    fetchUsersMessage: null,
    fetchingTenants: false,
    fetchedTenants: null,
    settingsMerchants: null,
    settingsFetchingMerchants: false,
    merchantListNew: null,
    fetchingMerchListNew: false,
    fetchedMerchantSettings: null,
    fetchedMerchantConfigurations: null,
    fetchedProcDetails: null,
    addingUser: false,
    addSuccess: false,
    addMessage: null,
    addingTenant: false,
    addTenantSuccess: false,
    addTenantMessage: null,
    editingUser: false,
    editSuccess: false,
    editMessage: null,
    deletingUser: false,
    deleteSuccess: false,
    // deleteMessage: null,
    merchantUserPasswordReset: false,
    resettingMerchantUserPassword: false,
};

export default function transactionsReducer(state = initState, action) {
    switch (action.type) {
        case actions.FETCHING_SAMPLE:
            return {
                ...state,
                testData: null,
                fetchingTestData: true,
            };
        case actions.FETCHED_SAMPLE:
            return {
                ...state,
                fetchingTestData: false,
                testData: action.data,
            };
        case actions.FETCH_SAMPLE_FAILED:
            return {
                ...state,
                fetchingTestData: false,
                testData: [],
            };
        case actions.SEARCHING_AUDITLOGS:
            return {
                ...state,
                searchingLogs: true,
            };
        case actions.SEARCHED_AUDITLOGS:
            return {
                ...state,
                searchingLogs: false,
                searchResult: action.result,
                searchResultCount: state.searchResultCount > 0 ? state.searchResultCount : action.result.count,
                searchResultPage: action.page,
                // searchResultCollection: action.collection,
            };
        case actions.SEARCH_AUDITLOGS_FAILED:
            return {
                ...state,
                searchingLogs: false,
                searchResult: null,
            };
        case actions.RESET_AUDITLOGS:
            return {
                ...state,
                searchingLogs: false,
                loadingMoreLogs: false,
                searchResult: null,
                loadedMoreLogs: null,
                searchResultCount: 0,
                searchResultPage: 1,
                searchResultCollection: null,
            }
        // FETCH ROLE
        case actions.FETCHING_ROLE:
            return {
                ...state,
                fetchedRole: null,
                fetchingRole: true,
            };
        case actions.FETCHED_ROLE:
            return {
                ...state,
                fetchingRole: false,
                fetchedRole: action.data,
            };
        case actions.FETCH_ROLE_FAILED:
            return {
                ...state,
                fetchingRole: false,
                fetchedRole: null,
            };

        // ADD_AUTH_ROLE
        case actions.AUTH_ROLES_ADDED:
            return {
                ...state,
                addingRole: false,
                addAuthRoleSuccess: true,
                fetchedRole: action.payload,
            };
        case actions.ADDING_AUTH_ROLES:
            return {
                ...state,
                addingRole: true,
            };
        case actions.ADD_AUTH_ROLES_FAILED:
            return {
                ...state,
                addingRole: false,
                addRoleMessage: action.payload,
            };
        case actions.RESET_ADD_AUTH_ROLES:
            return {
                ...state,
                addAuthRoleSuccess: false,
                addRoleMessage: false,
                loadingAuthManagementRole: false
            };

        // FETCH ROLE PERMISSION
        case actions.OPEN_PERMISSION_DIALOG:
            return {
                ...state,
                openRolePermissionDialog: !state.openRolePermissionDialog,
            };
        case actions.FETCHING_PERMISSION:
            return {
                ...state,
                fetchingRolePermission: true,
            };
        case actions.ROLE_PERMISSION_FETCHED:
            return {
                ...state,
                rolePermission: action.payload,
                payloadCore: action.payloadCore,
                selectedId: action.selectedId,
                fetchingRolePermission: false
            };
        case actions.RESET_PERMISSION_DATA:
            return {
                ...state,
                rolePermission: null,
                selectedId: null,
                payloadCore: null,
                fetchingRolePermission: false
            };

        // SAVE EDIT ROLE
        case actions.AUTH_ROLE_MANAGEMENT_SAVED:
            return {
                ...state,
                authManagementRoleSaved: true,
                editingRoleAuthorization: false,
            };
        case actions.SAVING_AUTH_ROLE_MANAGEMENT:
            return {
                ...state,
                editingRoleAuthorization: true,
            };
        case actions.SAVE_AUTH_ROLE_MANAGEMENT_FAILED:
            return {
                ...state,
                authManagementRoleSaved: false,
                editingRoleAuthorization: false,
            };
        case actions.RESET_SAVE_AUTH_ROLE_MANAGEMENT:
            return {
                ...state,
                authManagementRoleSaved: false,
                editingRoleAuthorization: false,
            };
        case actions.FETCHING_LOOKUPS:
            return {
                ...state,
                fetchingLookupsData: true,
                lookupsData: null
            };
        case actions.FETCHED_LOOKUPS:
            return {
                ...state,
                lookupsData: action.result,
                fetchingLookupsData: false,
            };
        case actions.ADDING_LOOKUPS:
            return {
                ...state,
                addingLookups: true,
            };
        case actions.ADDED_LOOKUPS:
            return {
                ...state,
                lookupsData: action.result,
                addingLookups: false,
                lookupsAdded: true,
                lookupsMessage: null
            };
        case actions.ADD_LOOKUPS_FAILED:
            return {
                ...state,
                lookupsMessage: action.payload,
                lookupsAdded: false,
            };
        case actions.RESET_ADD_LOOKUPS:
            return {
                ...state,
                // lookupsData: null,
                lookupsMessage: null,
                addingLookups: false,
                lookupsAdded: false,
            };
        case actions.DELETING_LOOKUPS:
            return {
                ...state,
                deletingLookups: true,
            };
        case actions.DELETED_LOOKUPS:
            return {
                ...state,
                lookupsData: action.result,
                lookupsDeleted: true,
                deletingLookups: false,
            };
        case actions.RESET_DELETE_LOOKUPS:
            return {
                ...state,
                deletingLookups: false,
                // lookupsData: null,
                lookupsDeleted: false,
            };
        case actions.UPDATING_LOOKUPS:
            return {
                ...state,
                updatingLookups: true,
            };
        case actions.UPDATED_LOOKUPS:
            return {
                ...state,
                lookupsData: action.result,
                lookupsUpdated: true,
                updatingLookups: false,
                lookupsUpdateMessage: null
            };
        case actions.UPDATE_LOOKUPS_FAILED:
            return {
                ...state,
                lookupsUpdateMessage: action.payload,
                lookupsUpdated: false,
            };
        case actions.RESET_UPDATE_LOOKUPS:
            return {
                ...state,
                updatingLookups: false,
                // lookupsData: null,
                lookupsUpdateMessage: null,
                lookupsUpdated: false,
            };
        case actions.FETCHING_RESPONSE_CODE:
            return {
                ...state,
                fetchingResponseCode: true,
                responseCodes: null
            };
        case actions.LOADING_MORE_RESPONSE_CODE:
            return {
                ...state,
                loadingMoreResponseCode: true,
            };
        case actions.FETCHED_RESPONSE_CODE:
            return {
                ...state,
                fetchingResponseCode: false,
                loadingMoreResponseCode: false,
                responseCodes: action.result,
            };
        case actions.FETCH_RESPONSE_CODE_FAILED:
            return {
                ...state,
                fetchingResponseCode: false,
                responseCodes: null,
                // searchCount: 0,
            };

        case actions.ADDING_RESPONSE_CODE:
            return {
                ...state,
                addingResponseCode: true,
                addingResponseCodeFailedMessage: null,
            };
        case actions.ADDED_RESPONSE_CODE:
            return {
                ...state,
                addingResponseCode: false,
                addingResponseCodeSuccess: true,
                responseCodes: action.result,
            };
        case actions.ADD_RESPONSE_CODE_FAILED:
            return {
                ...state,
                addingResponseCode: false,
                addingResponseCodeFailed: true,
                addingResponseCodeFailedMessage: action.payload,
            };
        case actions.ADD_RESPONSE_CODE_ERROR:
            return {
                ...state,
                addingResponseCode: false,
            };
        case actions.RESET_ADD_RESPONSE_CODE:
            return {
                ...state,
                addingResponseCodeSuccess: false,
                addingResponseCodeFailed: false,
                addingResponseCodeError: false,
            };
        case actions.RESET_ADD_RESPONSE_CODE_MESSAGE:
            return {
                ...state,
                addingResponseCodeFailedMessage: null,
            };
        //
        case actions.UPDATING_RESPONSE_CODE:
            return {
                ...state,
                updatingResponseCode: true,
                updatingResponseCodeFailedMessage: null,
            };
        case actions.UPDATED_RESPONSE_CODE:
            return {
                ...state,
                updatingResponseCode: false,
                updatingResponseCodeSuccess: true,
                responseCodes: action.result,
            };
        case actions.UPDATE_RESPONSE_CODE_FAILED:
            return {
                ...state,
                updatingResponseCode: false,
                updatingResponseCodeFailed: true,
                updatingResponseCodeFailedMessage: action.payload,
            };
        case actions.UPDATE_RESPONSE_CODE_ERROR:
            return {
                ...state,
                updatingResponseCode: false,
            };
        case actions.RESET_UPDATE_RESPONSE_CODE:
            return {
                ...state,
                updatingResponseCodeSuccess: false,
                updatingResponseCodeFailed: false,
                updatingResponseCodeError: false,
            };
        case actions.RESET_UPDATE_RESPONSE_CODE_MESSAGE:
            return {
                ...state,
                updatingResponseCodeFailedMessage: null,
            };
        //
        case actions.DELETING_RESPONSE_CODE:
            return {
                ...state,
                deletingResponseCode: true,
            };
        case actions.DELETED_RESPONSE_CODE:
            return {
                ...state,
                deletingResponseCode: false,
                responseCodesDeleted: true,
                responseCodes: action.result,
            };
        case actions.DELETE_RESPONSE_CODE_FAILED:
            return {
                ...state,
                deletingResponseCode: false,
            };
        case actions.DELETE_RESPONSE_CODE_ERROR:
            return {
                ...state,
                deletingResponseCode: false,
            };
        case actions.RESET_DELETE_RESPONSE_CODE:
            return {
                ...state,
                responseCodesDeleted: false,
            };

        case actions.FETCHING_MERCHANT_LIST_SETTINGS:
            return {
                ...state,
                settingsMerchants: null,
                fetchedUsers: null,
                fetchedMerchantSettings: null,
                authManagementRole: null,
                settingsFetchingMerchants: true,
                fetchedMerchantConfigurations: null,
                fetchedProcDetails: null
            };
        case actions.FETCHED_MERCHANT_LIST_SETTINGS:
            return {
                ...state,
                settingsMerchants: action.payload,
                settingsFetchingMerchants: false,
            };
        case actions.FETCH_MERCHANT_LIST_SETTINGS_FAILED:
            return {
                ...state,
                settingsFetchingMerchants: false,
            };
        case actions.SETTINGS_SEARCHING_MERCHANTS:
            return {
                ...state,
                settingsFetchingMerchants: true,
                settingsMerchants: null,
                fetchedUsers: null,
                fetchedMerchantSettings: null,
                fetchedMerchantConfigurations: null,
                fetchedProcDetails: null
            };
        case actions.SETTINGS_SEARCHED_MERCHANTS:
            return {
                ...state,
                settingsMerchants: action.payload,
                settingsFetchingMerchants: false
            };
        case actions.SETTINGS_SEARCHING_MERCHANTS_FAILED:
            return {
                ...state,
                settingsFetchingMerchants: false,
                settingsMerchants: action.data
            };
        case actions.FETCHING_USERS:
            return {
                ...state,
                fetchedUsers: null,
                fetchingUsers: true,
            };
        case actions.FETCHED_USERS:
            return {
                ...state,
                fetchingUsers: false,
                fetchedUsers: action.data
            };
        case actions.FETCH_USERS_FAILED:
            return {
                ...state,
                fetchingUsers: false,
                fetchUsersMessage: action.message
            };
        case actions.FETCHING_TENANTS:
            return {
                ...state,
                fetchedTenants: null,
                fetchingTenants: true,
            };
        case actions.FETCHED_TENANTS:
            return {
                ...state,
                fetchingTenants: false,
                fetchedTenants: action.data
            };
        case actions.FETCH_TENANTS_FAILED:
            return {
                ...state,
                fetchingTenants: false
            };

        case actions.ADDING_USER:
            return {
                ...state,
                addingUser: true
            };
        case actions.ADDED_USER:
            return {
                ...state,
                addingUser: false,
                addMessage: null,
                addSuccess: true,
                fetchedUsers: [
                    ...state.fetchedUsers,
                    {
                        accountName: action.data.account_name,
                        email: action.data.email,
                        first_name: action.data.first_name,
                        id: action.data.Id,
                        last_name: action.data.last_name,
                        merchant_name: action.data.merchant_name,
                        username: action.data.username,
                        label: action.data.label,
                        auth_role_id: action.data.role_id,
                        status: action.data.status,
                        passwordExpiration: action.data.passwordExpiration,
                        tenant_name: action.data.tenant_name
                    }
                ],
            };
        case actions.ADD_USER_FAILED:
            return {
                ...state,
                addingUser: false,
                addMessage: action.message
            };
        case actions.ADD_TENANT_FAILED:
            return {
                ...state,
                addingTenant: false,
                addTenantMessage: action.message === 'Invalid Request.' ? 'Username already exists.' : action.message
            };
        case actions.RESET_ADD_USER:
            return {
                ...state,
                addingUser: false,
                addSuccess: false,
                addMessage: null,
            };
        case actions.ADDING_TENANT:
            return {
                ...state,
                addingTenant: true
            };
        case actions.ADDED_TENANT:
            return {
                ...state,
                addingTenant: false,
                addTenantMessage: null,
                addTenantSuccess: true,
                fetchedUsers: [
                    ...state.fetchedUsers,
                    {
                        accountName: action.data.accountName,
                        email: action.data.email,
                        first_name: action.data.first_name,
                        id: action.data.Id,
                        last_name: action.data.last_name,
                        merchant_name: action.data.merchant_name,
                        username: action.data.username,
                        label: action.data.label,
                        auth_role_id: action.data.roleId,
                        key: action.data.key,
                        tenant_use_case: action.data.tenant_use_case,
                        tenant_notif_level: action.data.tenant_notif_level,
                        notification_url: action.data.notification_url,
                        utilize_portal: action.data.utilize_portal,
                        allow_trx_below_threshold: action.data.allow_trx_below_threshold,
                        is_cust_kyc_one_required: action.data.is_cust_kyc_one_required,
                        pass_on_fee: action.data.isPassOnFee,
                        status: action.data.status,
                        passwordExpiration: action.data.passwordExpiration,
                        is_utilize_email: action.data.is_utilize_email,
                        is_bypass_otp: action.data.is_bypass_otp
                    }
                ],
            };
        case actions.RESET_ADD_TENANT:
            return {
                ...state,
                addingTenant: false,
                addTenantSuccess: false,
                addTenantMessage: null,
            };
        case actions.EDITING_USER:
            return {
                ...state,
                editingUser: true
            };

        case actions.EDITED_USER:
            const fetchedUsers = state.fetchedUsers;
            const updatedUsers = [];
            for (let i = 0; i < fetchedUsers.length; i++) {
                const item = fetchedUsers[i];
                if (action.user.id === item.id) {
                    fetchedUsers[i].account_name = `${action.user.first_name} ${action.user.last_name}`;
                    fetchedUsers[i].first_name = action.user.first_name;
                    fetchedUsers[i].last_name = action.user.last_name;
                    fetchedUsers[i].email = action.user.email;
                    fetchedUsers[i].label = action.user.label;
                    fetchedUsers[i].tenant_use_case = action.user.tenant_use_case;
                    fetchedUsers[i].tenant_notif_level = action.user.tenant_notif_level;
                    fetchedUsers[i].notification_url = action.user.notification_url;
                    fetchedUsers[i].utilize_portal = action.user.utilize_portal;
                    fetchedUsers[i].allow_trx_below_threshold = action.user.allow_trx_below_threshold;
                    fetchedUsers[i].is_cust_kyc_one_required = action.user.is_cust_kyc_one_required;
                    fetchedUsers[i].pass_on_fee = action.user.pass_on_fee;
                    fetchedUsers[i].status = action.user.status;
                    fetchedUsers[i].passwordExpiration = action.user.passwordExpiration;
                    fetchedUsers[i].is_utilize_email = action.user.is_utilize_email;
                    fetchedUsers[i].is_bypass_otp = action.user.is_bypass_otp;
                }
                updatedUsers.push(fetchedUsers[i]);
            }
            return {
                ...state,
                editingUser: false,
                editSuccess: true,
                editMessage: null,
                fetchedUsers: updatedUsers,
            };
        case actions.EDIT_USER_FAILED:
            return {
                ...state,
                editingUser: false,
                editMessage: action.message,
                editSuccess: false,
            };
        case actions.RESET_EDIT_USER:
            return {
                ...state,
                editingUser: false,
                editSuccess: false,
                editMessage: null
            };
        case actions.DELETING_USER:
            return {
                ...state,
                deletingUser: true
            };
        case actions.DELETED_USER:
            return {
                ...state,
                deletingUser: false,
                deleteSuccess: true,
                // deleteMessage: action.message,
                fetchedUsers: action.users,
            };
        case actions.DELETE_USER_FAILED:
            return {
                ...state,
                deletingUser: false,
                // deleteMessage: action.message
            };
        case actions.RESET_DELETE_USER:
            return {
                ...state,
                deletingUser: false,
                deleteSuccess: false,
                // deleteMessage: null
            };
        case actions.MERCHANT_USER_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                merchantUserPasswordReset: true,
                resettingMerchantUserPassword: false
            };
        case actions.RESET_MERCHANT_USER_PASSWORD_RESET:
            return {
                ...state,
                merchantUserPasswordReset: false,
            };
        
        case actions.RESETTING_MERCHANT_USER_PASSWORD:
            return {
                ...state,
                resettingMerchantUserPassword: true,
            };
        default:
            return state;
    }
}
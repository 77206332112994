import { all, put, fork, takeEvery, delay, select } from 'redux-saga/effects';
import { get, post, PUT, postWithToken   } from '@iso/lib/helpers/resApiRequestor';
import actions from './actions';
import _ from 'lodash';

export function* getSampleList() {
    yield takeEvery('FETCH_SAMPLE', function* () {
        try {
            yield put({
                type: actions.FETCHING_SAMPLE,
            });
            // const apiResult = yield sampleGetCall();
            // const result = apiResult.data;
            // if (result.success) {
            const sampleData = [
                {
                    id: '1',
                    name: 'Option 1'
                },
                {
                    id: '2',
                    name: 'Option 2'
                },
                {
                    id: '3',
                    name: 'Option 3'
                }
            ];
            yield delay(3000);
            yield put({
                type: actions.FETCHED_SAMPLE,
                data: sampleData,
            });
            // }
        } catch (error) {
            yield put({
                type: actions.FETCH_SAMPLE_FAILED,
                message: error,
            });
        }
    });
}

export function* searchAuditLogs() {
  yield takeEvery('SEARCH_AUDITLOGS', function* (payload) {
    // // console.log('ENTER_SAGA 1', payload.payload);
    try {
      if (payload.payload.resetList === true) {
        yield put({
          type: actions.RESET_AUDITLOGS,
        });
      }
      yield put({
        type: actions.SEARCHING_AUDITLOGS,
      });
      const state = yield select();
      let searchResultCollection = state.Settings.searchResultCollection;
      if (searchResultCollection && searchResultCollection.hasOwnProperty(payload.payload.currentPage)) {
        console.log('CHECK PAGE EXISTING', searchResultCollection);
        yield put({
          type: actions.SEARCHED_AUDITLOGS,
          result: searchResultCollection[payload.payload.currentPage],
          page: payload.payload.currentPage,
          collection: searchResultCollection
        });
      } else {
        const apiResult = yield searchAudit(payload.payload);
        console.log('CHECK API RESULT LOGS');
        const result = apiResult.data;
        console.log('CHECK API RESULT DATA', result);
        if (result.success === true) {
          searchResultCollection = {
            ...searchResultCollection,
            [payload.payload.currentPage]: result.data
          };
          yield put({
            type: actions.SEARCHED_AUDITLOGS,
            result: result.data,
            page: payload.payload.currentPage,
            collection: searchResultCollection
          });
        }
      }
    } catch (error) {
      yield put({
        type: actions.SEARCH_AUDITLOGS_FAILED,
      });
    }
  });
}

  export function* fetchLookups() {
    yield takeEvery('FETCH_LOOKUPS', function* (payload) {
      try {
        yield put({
          type: actions.FETCHING_LOOKUPS,
        });
        console.log('CHECK FETCH_LOOKUPS PAYLOAD', payload.payload);
        const apiResult = yield fetchLookupsReq(payload.payload);
        const result = apiResult.data;
        console.log('CHECK FETCH_LOOKUPS RESULT DATA', apiResult.data);
        if (result.success) {
          yield put({
            type: actions.FETCHED_LOOKUPS,
            result: result.data
          });
        }
      } catch (error) {
        yield put({
          type: actions.FETCH_LOOKUPS_FAILED,
        });
      }
    });
  }

  export function* fetchLookupsStatus() {
    yield takeEvery('FETCH_LOOKUPS_STATUS', function* (payload) {
      yield put({
        type: actions.FETCHING_LOOKUPS_STATUS,
      });
      try {
        const apiResult = yield fetchStatusLookupsData(payload.payload);
        const result = apiResult.data;
        console.log('FETCH_LOOKUPS_STATUS-RESULT: ', result);
        if (result.success === true) {
          yield put({
            type: actions.FETCHED_LOOKUPS_STATUS,
            payload: result.data.result,
            // result: result.success,
          });
        } else {
          yield put({
            type: actions.FETCH_LOOKUPS_STATUS_FAILED,
            payload: result.message,
          });}
      } catch (error) {
        yield put({
          type: actions.FETCH_LOOKUPS_STATUS_FAILED,
          payload: error,
        });
      }
    });
  }

  export function* addLookups() {
    yield takeEvery('ADD_LOOKUPS', function* (payload) {
      try {
        yield put({
          type: actions.ADDING_LOOKUPS,
        });
        console.log('ADD LOOKUPS PAYLOAD', payload.payload);
        const apiResult = yield addLookupsReq(payload.payload);
        const result = apiResult.data;
        console.log('ADD LOOKUPS RESULT', result);
        if (result.success) {
          yield put({
            type: actions.ADDED_LOOKUPS,
            result: result.data,
          });
        } else {
          yield put({
            type: actions.ADD_LOOKUPS_FAILED,
            payload: result.message ? result.message : 'Lookup label already exists.',
          });
        }
      } catch (error) {
        yield put({
          type: actions.ADD_LOOKUPS_ERROR,
          payload: error
        });
      }
    });
  }

  export function* updateLookups() {
    yield takeEvery('UPDATE_LOOKUPS', function* (payload) {
      try {
        yield put({
          type: actions.UPDATING_LOOKUPS,
        });
        const apiResult = yield updateLookupsReq(payload.payload);
        const result = apiResult.data;
        if (result.success) {
          yield put({
            type: actions.UPDATED_LOOKUPS,
            result: result.data,
          });
        } else {
          yield put({
            type: actions.UPDATE_LOOKUPS_FAILED,
            payload: result.message ? result.message : 'Lookup label already exists.',
          });
        }
      } catch (error) {
        yield put({
          type: actions.UPDATE_LOOKUPS_ERROR,
        });
      }
    });
  }

export function* fetchResponseCode() {
  yield takeEvery('FETCH_RESPONSE_CODE', function* (payload) {
    try {
      const loadmore = payload.payload.loadmore;
      if (loadmore) {
        yield put({
          type: actions.LOADING_MORE_RESPONSE_CODE,
        });
      } else {
        yield put({
          type: actions.FETCHING_RESPONSE_CODE,
        });
      }
      const apiResult = yield fetchResponseCodeReq(payload.payload);
      const result = apiResult.data;
      const currentList = payload.payload.currentReponseCodeList;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      if (result.success) {
        yield put({
          type: actions.FETCHED_RESPONSE_CODE,
          result: finalData,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.FETCH_RESPONSE_CODE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_RESPONSE_CODE_ERROR,
      });
    }
  });
}

export function* addResponseCode() {
  yield takeEvery('ADD_RESPONSE_CODE', function* (payload) {
    try {
      yield put({
        type: actions.ADDING_RESPONSE_CODE,
      });
      const apiResult = yield addResponseCodeReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.ADDED_RESPONSE_CODE,
          result: result.data.result,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.ADD_RESPONSE_CODE_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_RESPONSE_CODE_ERROR,
      });
    }
  });
}

export function* updateResponseCode() {
  yield takeEvery('UPDATE_RESPONSE_CODE', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_RESPONSE_CODE,
      });
      const apiResult = yield updateResponseCodeReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.UPDATED_RESPONSE_CODE,
          result: result.data.result,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.UPDATE_RESPONSE_CODE_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_RESPONSE_CODE_ERROR,
      });
    }
  });
}

export function* deleteResponseCode() {
  yield takeEvery('DELETE_RESPONSE_CODE', function* (payload) {
    try {
      yield put({
        type: actions.DELETING_RESPONSE_CODE,
      });
      const apiResult = yield deleteResponseCodeReq(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.DELETED_RESPONSE_CODE,
          result: result.data.result,
        });
      } else {
        yield put({
          type: actions.DELETE_RESPONSE_CODE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_RESPONSE_CODE_ERROR,
      });
    }
  });
}
  
export function* getRolesList() {
    yield takeEvery('FETCH_ROLE', function* () {
        try {
            yield put({
                type: actions.FETCHING_ROLE,
            });
            const apiResult = yield getRoles();
            const result = apiResult.data;
            if (result.success) {
                yield put({
                    type: actions.FETCHED_ROLE,
                    data: result.data,
                });
            }
        } catch (error) {
            yield put({
                type: actions.FETCH_ROLE_FAILED,
                message: error,
            });
        }
    });
}

export function* addAuthRole() {
    yield takeEvery('ADD_AUTH_ROLES', function* (payload) {
        try {
            yield put({
                type: actions.ADDING_AUTH_ROLES,
            });
            const apiResult = yield addAuthRoleReq(payload.payload);
            const result = apiResult.data;
            if (result.success) {
                const apiResultRole = yield getRoles();
                const resultRole = apiResultRole.data;
                yield put({
                    type: actions.AUTH_ROLES_ADDED,
                    payload: resultRole.data,
                });
            } else {
                yield put({
                    type: actions.ADD_AUTH_ROLES_FAILED,
                    payload: result.message,
                });
            }
        } catch (error) {
            yield put({
                type: actions.ADD_AUTH_ROLES_FAILED,
                payload: 'Failed to add role',
            });
        }
    });
}

export function* fetchPermission() {
    yield takeEvery('FETCH_PERMISSION', function* (payload) {
        try {
            yield put({
                type: actions.FETCHING_PERMISSION,
            });
            console.log('CHECK PAYLOAD', payload);
            const apiResult = yield fetchRolePermissionRequest(payload.payload);
            const result = apiResult.data;
            console.log('FETCH_PERMISSION-RESULT: ', result);
            const apiResultCore = yield fetchCoreModuleRequest(payload.payload);
            const resultCore = apiResultCore.data;
            console.log('FETCH_CORE-RESULT: ', resultCore);
            if (result.success === true) {
                yield put({
                    type: actions.ROLE_PERMISSION_FETCHED,
                    payload: result.data,
                    payloadCore: resultCore.data,
                    selectedId: payload.payload,
                });
            }
        } catch (error) {
            yield put({
                type: actions.FETCH_PERMISSION_FAILED,
                payload: error,
            });
        }
    });
}

export function* fetchStatus() {
    yield takeEvery('FETCH_STATUS', function* () {
        // console.log()
        try {
            const apiResult = yield fetchStatusRequest();
            console.log('FETCH_STATUS-RESULT: ', apiResult);
            const result = apiResult.data;
            // console.log('FETCH_STATUS-RESULT: ', result);
            yield put({
                type: actions.FETCHING_STATUS,
            });
            if (result.success === true) {
                yield put({
                    type: actions.STATUS_FETCHED,
                    payload: result.data,
                });
            }
        } catch (error) {
            yield put({
                type: actions.FETCH_STATUS_FAILED,
                payload: error,
            });
        }
    });
}

export function* saveAuthManagement() {
    yield takeEvery('SAVE_AUTH_ROLE_MANAGEMENT', function* (payload) {
        const data = payload.payload;
        try {
            yield put({
                type: actions.SAVING_AUTH_ROLE_MANAGEMENT,
            });
            const apiResult = yield saveAuthManagementReq(data.selectedId, data);
            const result = apiResult.data;
            if (result.success === true) {
                yield put({
                    type: actions.AUTH_ROLE_MANAGEMENT_SAVED,
                    payload: result.data,
                });
            } else {
                yield put({
                    type: actions.SAVE_AUTH_ROLE_MANAGEMENT_FAILED,
                    payload: result.data,
                });
            }
        } catch (error) {
            yield put({
                type: actions.SAVE_AUTH_ROLE_MANAGEMENT_FAILED,
                payload: error,
            });
        }
    });
}

export function* resetMerchantUserPassword() {
  yield takeEvery('RESET_MERCHANT_USER_PASSWORD', function* (payload) {
    try {
      yield put({
        type: actions.RESETTING_MERCHANT_USER_PASSWORD,
      });

      const apiResult = yield resetMerchantUserPasswordRequest(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.MERCHANT_USER_PASSWORD_RESET_SUCCESS,
        });
      } else {
        yield put({
          type: actions.MERCHANT_USER_PASSWORD_RESET_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.MERCHANT_USER_PASSWORD_RESET_ERROR,
      });
    }
  });
}

// FOR USER MANAGEMENT
export function* fetchMerchantsSettings() {
  yield takeEvery('FETCH_MERCHANT_LIST_SETTINGS', function* () {
    const state = yield select();
    const identity = state.Auth.identity;
    try {
      yield put({
        type: actions.FETCHING_MERCHANT_LIST_SETTINGS,
      });
      const apiResult = yield fetchMerchantsReq(identity);
      const result = apiResult.data;
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_MERCHANT_LIST_SETTINGS,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_MERCHANT_LIST_SETTINGS_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchUsers() {
  yield takeEvery('FETCH_USERS', function* (payload) {
    console.log('FETCH_USERS PAYLOAD', payload);
    try {
      yield put({
        type: actions.FETCHING_USERS,
      });
      const apiResult = yield fetchUsersReq(payload.payload);
      console.log('FETCH_USERS RESULT', apiResult.data.data)
      if (apiResult.data.success) {
        yield put({
          type: actions.FETCHED_USERS,
          data: apiResult.data.data
        });
      } else {
        yield put({
          type: actions.FETCH_USERS_FAILED,
          message: apiResult.data.message
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_USERS_FAILED,
        message: 'Failed to load users.'
      });
    }
  });
}

export function* fetchTenants() {
  yield takeEvery('FETCH_TENANTS', function* (payload) {
    console.log('FETCH_TENANTS PAYLOAD', payload);
    try {
      yield put({
        type: actions.FETCHING_TENANTS,
      });
      const apiResult = yield fetchUsersReq(payload.payload);
      console.log('FETCH_TENANTS RESULT', apiResult.data.data)
      if (apiResult.data.success) {
        yield put({
          type: actions.FETCHED_TENANTS,
          data: apiResult.data.data
        });
      } else {
        yield put({
          type: actions.FETCH_TENANTS_FAILED,
          message: apiResult.data.message
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_TENANTS_FAILED,
        message: 'Failed to load users.'
      });
    }
  });
}

export function* searchMerchant() {
  yield takeEvery('SETTINGS_SEARCH_MERCHANTS', function* (payload) {
    const state = yield select();
    const identity = state.Auth.identity;
    try {
      yield put({
        type: actions.SETTINGS_SEARCHING_MERCHANTS,
      });
      const apiResult = yield merchantSearch(payload.payload, identity);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.SETTINGS_SEARCHED_MERCHANTS,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SETTINGS_SEARCHING_MERCHANTS_FAILED,
        payload: error,
      });
    }
  });
}

export function* addUser() {
  yield takeEvery('ADD_USER', function* (payload) {
    try {
      yield put({
        type: actions.ADDING_USER,
      });
      const apiResult = yield addUserAccount(payload.payload);
      console.log('ADD_USER RESULT', apiResult.data.data)
      if (apiResult.data.success === true) {
        yield put({
          type: actions.ADDED_USER,
          data: apiResult.data.data,
        });
      } else {
        yield put({
          type: actions.ADD_USER_FAILED,
          message: apiResult.data.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_USER_FAILED,
        messsage: 'Failed to add user.',
      });
    }
  });
}

export function* addTenant() {
  yield takeEvery('ADD_TENANT', function* (payload) {
    try {
      yield put({
        type: actions.ADDING_TENANT,
      });
      const apiResult = yield addTenantAccount(payload.payload);
      console.log('ADD_TENANT RESULT', apiResult.data)
      if (apiResult.data.success === true) {
        yield put({
          type: actions.ADDED_TENANT,
          data: apiResult.data.data,
        });
      } else {
        yield put({
          type: actions.ADD_TENANT_FAILED,
          message: apiResult.data.message ? apiResult.data.message : apiResult.data.data.response_advise.includes('username already exists in the system') ? 'User already exist' : apiResult.data.data.response_message.replace('.', '')
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_TENANT_FAILED,
        messsage: 'Failed to add user.',
      });
    }
  });
}

export function* editUser() {
  yield takeEvery('EDIT_USER', function* (payload) {
    try {
      yield put({
        type: actions.EDITING_USER,
      });
      // console.log('EDIT DATA', payload.payload);
      const apiResult = yield editUserAccount(payload.payload);
      console.log('CHECK EDIT USER ACCOUNT RESULT', apiResult);
      if (apiResult.data.success === true) {
        // console.log('CHECKING PAYLOAD OF EDIT',payload.payload)
        yield put({
          type: actions.EDITED_USER,
          message: apiResult.data.message,
          // user: payload.payload,
           user: apiResult.data.data.data
        });
      } else {
        yield put({
          type: actions.EDIT_USER_FAILED,
          message: apiResult.data.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.EDIT_USER_FAILED,
        messsage: 'Failed to edit user.',
      });
    }
  });
}
export function* deleteUser() {
  yield takeEvery('DELETE_USER', function* (payload) {
    const state = yield select();
    const fetchedUsers = state.Settings.fetchedUsers;
    try {
      yield put({
        type: actions.DELETING_USER,
      });
      const apiResult = yield deleteUserAccount(payload.payload);
      if (apiResult.data.success === true) {
        console.log('CHECK PAYLOAD', payload);
        console.log('CHECK FETCHED USERS', fetchedUsers);
        const updatedUsers = _.reject(fetchedUsers, { id: payload.payload.id });
        console.log('CHECK UPDATED USERS', updatedUsers);
        yield put({
          type: actions.DELETED_USER,
          message: apiResult.data.message,
          users: updatedUsers,
        });
      } else {
        yield put({
          type: actions.DELETE_USER_FAILED,
          message: apiResult.data.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_USER_FAILED,
        messsage: 'Failed to delete user.',
      });
    }
  });
}

// function samplePostCall(data) {
//     return post(`<change to api path>`, data);
// }

// function sampleGetCall() {
//     return get(`<change to api path>`);
// }

// function samplePutCall(data) {
//     return PUT(`<change to api path>`, data);
// }

function searchAudit(data) {
    return postWithToken(`auditlogs/search/logs`, data);
}
function getRoles() {
    return get('roles/fetch_all_roles/ui');
}

function addAuthRoleReq(data) {
    return post(`roles/save_role/ui`, data);
}
function fetchCoreModuleRequest() {
    // return get(`core-modules/system/modules/new/${id}`);
    return get(`core-modules/system/modules/new`);
}
function fetchRolePermissionRequest(id) { // here
    return get(`roles/role_authorization/${id}`);
}
function fetchStatusRequest() {
    // add endpoint for auth management save
    return get(`lookups/fetch/status/`);
}
function saveAuthManagementReq(id, data) {
    // console.log('SAVE_AUTH_DATA', data);
    // return get(`core-modules/system/modules/new/${id}`);
    // return post(`roles/${id}/authorization/update_roles_auth`, data, { page: 1, limit: 50 });
    return PUT(`roles/edit_role/${id}`, data);
}
function fetchLookupsReq() {
  return get(`lookups/fetch/all`);
}
function fetchStatusLookupsData(data) {
  return get(`lookups/`, data, null, data.group, null, null);
}

function addLookupsReq(data) {
  return post(`lookups/new/`, data);
}

function updateLookupsReq(data) {
  return PUT(`lookups/new/${data.id}`, data);
}

function fetchResponseCodeReq(data) {
    const newData = {
      page: data.page,
      mid: data.mid,
    }
    return get(`responsecodes/get/new`, newData, newData.page, null, null);
}

function addResponseCodeReq(data) {
  return post(`responsecodes/new`, data);
}

function updateResponseCodeReq(data) {
  return PUT(`responsecodes/new/${data.Id}`, data);
}

function deleteResponseCodeReq(data) {
  return post(`responsecodes/delete`, data);
}
function fetchMerchantsReq(data) {
  const requestData = {
    role: data.role.role.label,
    tenantId: data.tenantId,
    tenantLink: data.tenantLink,
  };
  return post(`authenticate/fetch_merchants`, requestData);
}
function fetchUsersReq(id) {
  return get(`authenticate/fetch_by_id/${id}`);
}
function merchantSearch(data, identity) {
  data = {
    ...data,
    role: identity.role.role.label,
    tenantId: identity.tenantId,
    tenantLink: identity.tenantLink,
  };
  return post(`company/search`, data);
}
function addUserAccount(data) {
  return post(`authenticate/add_user/ui`, data);
}
function addTenantAccount(data) {
  return post(`tenants/add`, data);
}
function editUserAccount(data) {
  const dataBody = {
    data1: data.data1,
    data2: data.data2,
    key: data.key
  };
  return PUT(`authenticate/new/${data.id}`, dataBody);
}
function deleteUserAccount(data) {
  return PUT(`authenticate/delete/${data.id}`, data);
}
function resetMerchantUserPasswordRequest(id) {
  return get(`authenticate/reset_password/ui/${id}`);
}

export default function* rootSaga() {
    yield all([
        fork(getSampleList),
        fork(searchAuditLogs),
        fork(getRolesList),
        fork(addAuthRole),
        fork(fetchPermission),
        fork(fetchStatus),
        fork(saveAuthManagement),
        fork(fetchLookups),
        fork(fetchLookupsStatus),
        fork(addLookups),
        fork(updateLookups),
        // fork(searchAuditLogs),
        fork(fetchResponseCode),
        fork(addResponseCode),
        fork(updateResponseCode),
        fork(deleteResponseCode),
        fork(fetchMerchantsSettings),
        fork(fetchUsers),
        fork(searchMerchant),
        fork(addUser),
        fork(addTenant),
        fork(editUser),
        fork(deleteUser),
        fork(resetMerchantUserPassword),
        fork(fetchTenants),
    ]);
}
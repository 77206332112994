export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  RESET_PASSWORD: '/reset_password/:id',
  REDIRECT_RESET_SUCCESS: '/redirect_reset_success',
  REDIRECT_RESET_EXPIRED: '/redirect_reset_expired',
  REDIRECT_RESET_USED: '/redirect_reset_used',
  FORGET_PASSWORD: '/forgotpassword',
  PAGE_404: '/404',
  PAGE_500: '/500',
};

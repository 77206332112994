import crypto from 'crypto';

export function encryptRequest(data, key) {
    console.log('CHECK ENCRYPT DATA', data);
    console.log('CHECK ENCRYPT KEY', key);

    const pemHeader = "-----BEGIN PUBLIC KEY-----\n";
    const pemFooter = "\n-----END PUBLIC KEY-----";
    const publicKey = pemHeader + key + pemFooter;
    console.log('CHECK ENCRYPT PUBLIC KEY', publicKey);

    const token = crypto.publicEncrypt({
        key: publicKey,
        padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
        oaepHash: 'sha256',
    }, Buffer.from(JSON.stringify(data)));

    const tokenContent = token.toString('base64');
    return tokenContent
}
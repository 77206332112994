const actions = {

  // FETCH AUTH MANAGMENT ROLE
  FETCH_AUTH_MANAGEMENT_ROLE: 'FETCH_AUTH_MANAGEMENT_ROLE',
  FETCHING_AUTH_MANAGEMENT_ROLE: 'FETCHING_AUTH_MANAGEMENT_ROLE',
  AUTH_MANAGEMENT_ROLE_FETCHED: 'AUTH_MANAGEMENT_ROLE_FETCHED',
  FETCH_AUTH_MANAGEMENT_ROLE_FAILED: 'FETCH_AUTH_MANAGEMENT_ROLE_FAILED',
  // SAVE AUTH MANAGEMENT
  SAVE_AUTH_MANAGEMENT: 'SAVE_AUTH_MANAGEMENT',
  SAVING_AUTH_MANAGEMENT: 'SAVING_AUTH_MANAGEMENT',
  AUTH_MANAGEMENT_SAVED: 'AUTH_MANAGEMENT_SAVED',
  SAVE_AUTH_MANAGEMENT_FAILED: 'SAVE_AUTH_MANAGEMENT_FAILED',
  RESET_SAVE_AUTH_MANAGEMENT: 'RESET_SAVE_AUTH_MANAGEMENT',
  // ADD AUTH ROLE
  ADD_AUTH_ROLE: 'ADD_AUTH_ROLE',
  ADDING_AUTH_ROLE: 'ADDING_AUTH_ROLE',
  AUTH_ROLE_ADDED: 'AUTH_ROLE_ADDED',
  ADD_AUTH_ROLE_FAILED: 'ADD_AUTH_ROLE_FAILED',
  RESET_ADD_AUTH_ROLE: 'RESET_ADD_AUTH_ROLE',

  // FETCH TOTAL TRANSACTIONS
  FETCH_TOTAL_TRANSACTIONS: 'FETCH_TOTAL_TRANSACTIONS',
  FETCHING_TOTAL_TRANSACTIONS: 'FETCHING_TOTAL_TRANSACTIONS',
  FETCHED_TOTAL_TRANSACTIONS: 'FETCHED_TOTAL_TRANSACTIONS',
  FETCH_TOTAL_TRANSACTIONS_FAILED: 'FETCH_TOTAL_TRANSACTIONS_FAILED',
  // FETCH ALL TRANSACTIONS
  FETCH_ALL_TRANSACTIONS: 'FETCH_ALL_TRANSACTIONS',
  FETCHING_ALL_TRANSACTIONS: 'FETCHING_ALL_TRANSACTIONS',
  FETCHED_ALL_TRANSACTIONS: 'FETCHED_ALL_TRANSACTIONS',
  FETCH_ALL_TRANSACTIONS_FAILED: 'FETCH_ALL_TRANSACTIONS_FAILED',

  // WITHHELD TRANSACTIONS
  FETCH_WITHHELD_TRANSACTIONS: 'FETCH_WITHHELD_TRANSACTIONS',
  FETCHING_WITHHELD_TRANSACTIONS: 'FETCHING_WITHHELD_TRANSACTIONS',
  FETCHED_WITHHELD_TRANSACTIONS: 'FETCHED_WITHHELD_TRANSACTIONS',
  FETCH_WITHHELD_TRANSACTIONS_FAILED: 'FETCH_WITHHELD_TRANSACTIONS_FAILED',
  // FETCH CORP WALLET
  FETCH_CORP_WALLET: 'FETCH_CORP_WALLET',
  // FETCHING_MORE_CORP_WALLETS: 'FETCHING_MORE_CORP_WALLETS',
  FETCHING_CORP_WALLETS: 'FETCHING_CORP_WALLETS',
  FETCHED_CORP_WALLETS: 'FETCHED_CORP_WALLETS',
  FETCH_CORP_WALLETS_FAILED: 'FETCH_MERCHANTS_FAILED',

  // FETCH CORP WALLET BY MERCH ID
  FETCH_CORP_WALLETS_BY_MID: 'FETCH_CORP_WALLETS_BY_MID',
  FETCHING_CORP_WALLETS_BY_MID: 'FETCHING_CORP_WALLETS_BY_MID',
  FETCHED_CORP_WALLETS_BY_MID: 'FETCHED_CORP_WALLETS_BY_MID',
  FETCH_CORP_WALLETS_BY_MID_FAILED: 'FETCH_CORP_WALLETS_BY_MID_FAILED',

  // FETCH TRANSACTIONS COUNT
  FETCH_TRANSACTIONS_COUNT: 'FETCH_TRANSACTIONS_COUNT',
  FETCHING_TRANSACTIONS_COUNT: 'FETCHING_TRANSACTIONS_COUNT',
  FETCHED_TRANSACTIONS_COUNT: 'FETCHED_TRANSACTIONS_COUNT',
  FETCH_TRANSACTIONS_COUNT_FAILED: 'FETCH_TRANSACTIONS_COUNT_FAILED',

  // FETCH ALL TRANSACTIONS COUNT
  FETCH_ALL_TRANSACTIONS_COUNT: 'FETCH_ALL_TRANSACTIONS_COUNT',
  FETCHING_ALL_TRANSACTIONS_COUNT: 'FETCHING_ALL_TRANSACTIONS_COUNT',
  FETCHED_ALL_TRANSACTIONS_COUNT: 'FETCHED_ALL_TRANSACTIONS_COUNT',
  FETCH_ALL_TRANSACTIONS_COUNT_FAILED: 'FETCH_ALL_TRANSACTIONS_COUNT_FAILED',

  // FETCH ALL TRANSACTIONS DETAILS
  FETCH_ALL_TRANSACTION_DETAILS: 'FETCH_ALL_TRANSACTION_DETAILS',
  FETCHING_ALL_TRANSACTION_DETAILS: 'FETCHING_ALL_TRANSACTION_DETAILS',
  FETCHED_ALL_TRANSACTION_DETAILS: 'FETCHED_ALL_TRANSACTION_DETAILS',
  FETCHING_MORE_TRANSACTION_DETAILS: 'FETCHING_MORE_TRANSACTION_DETAILS',
  FETCH_ALL_TRANSACTION_DETAILS_FAILED: 'FETCH_ALL_TRANSACTION_DETAILS_FAILED',

  // FETCH BANK DETAILS BY TRANSACTION ID
  FETCH_BANK_DETAILS_BY_TRANSACTION_ID: 'FETCH_BANK_DETAILS_BY_TRANSACTION_ID',
  FETCHING_BANK_DETAILS_BY_TRANSACTION_ID: 'FETCHING_BANK_DETAILS_BY_TRANSACTION_ID',
  FETCHED_BANK_DETAILS_BY_TRANSACTION_ID: 'FETCHED_BANK_DETAILS_BY_TRANSACTION_ID',
  FETCH_BANK_DETAILS_BY_TRANSACTION_ID_FAILED: 'FETCH_BANK_DETAILS_BY_TRANSACTION_ID_FAILED',

  // RESET TRANSACTIONS DATA
  RESET_DASHBOARD_DATA: 'RESET_DASHBOARD_DATA',
  RESET_BANK_DETAILS_DATA: 'RESET_BANK_DETAILS_DATA',

  // RESET WALLET DETAILS DATA
  RESET_WALLET_DATA: 'RESET_WALLET_DATA',

  // RESET WALLET DETAILS DATA
  RESET_WITHHELD_TRANSACTIONS_DATA: 'RESET_WITHHELD_TRANSACTIONS_DATA',

  // UPDATING THRESHOLD
  UPDATE_THRESHOLD_AMOUNT: 'UPDATE_THRESHOLD_AMOUNT',
  UPDATING_THRESHOLD_AMOUNT: 'UPDATING_THRESHOLD_AMOUNT',
  UPDATED_THRESHOLD_AMOUNT: 'UPDATED_THRESHOLD_AMOUNT',
  UPDATE_THRESHOLD_AMOUNT_FAILED: 'UPDATE_THRESHOLD_AMOUNT_FAILED',

  //FETCH LOOKUPS CURRENCY
  FETCH_LOOKUPS_CURRENCY: 'FETCH_LOOKUPS_CURRENCY',
  FETCHED_LOOKUPS_CURRENCY: 'FETCHED_LOOKUPS_CURRENCY',
  FETCHING_LOOKUPS_CURRENCY: 'FETCHING_LOOKUPS_CURRENCY',
  FETCH_LOOKUPS_CURRENCY_FAILED: 'FETCH_LOOKUPS_CURRENCY_FAILED',
  //FETCH WALLET GROUPS 
  FETCH_WALLET_GROUPS: 'FETCH_WALLET_GROUPS',
  FETCHED_WALLET_GROUPS: 'FETCHED_WALLET_GROUPS',
  FETCHING_WALLET_GROUPS: 'FETCHING_WALLET_GROUPS',
  FETCH_WALLET_GROUPS_FAILED: 'FETCH_WALLET_GROUPS_FAILED',
  //ADD WALLET 
  ADD_CORP_WALLET: 'ADD_CORP_WALLET',
  ADDING_CORP_WALLET: 'ADDING_CORP_WALLET',
  ADDED_CORP_WALLET: 'ADDED_CORP_WALLET',
  ADD_CORP_WALLET_FAILED: 'ADD_CORP_WALLET_FAILED',
  RESET_CORP_WALLETS: 'RESET_CORP_WALLETS',

  //FETCH DAILY BALANCE
  FETCH_DAILY_BALANCE_DETAILS: 'FETCH_DAILY_BALANCE_DETAILS',
  FETCHING_DAILY_BALANCE_DETAILS: 'FETCHING_DAILY_BALANCE_DETAILS',
  FETCHED_DAILY_BALANCE_DETAILS: 'FETCHED_DAILY_BALANCE_DETAILS',
  FETCH_DAILY_BALANCE_DETAILS_FAILED: 'FETCH_DAILY_BALANCE_DETAILS_FAILED',
  // FILTER TRANSACTIONS DATA
  FILTER_TRANSACTION_BODY: 'FILTER_TRANSACTION_BODY',

  //POST CREDIT TRANSACTION
  POST_CREDIT_TRANSACTION: 'POST_CREDIT_TRANSACTION',
  POSTING_CREDIT_TRANSACTION: 'POSTING_CREDIT_TRANSACTION',
  POSTED_CREDIT_TRANSACTION: 'POSTED_CREDIT_TRANSACTION',
  POST_CREDIT_TRANSACTION_FAILED: 'POST_CREDIT_TRANSACTION_FAILED',

  //POST DEBIT TRANSACTION
  POST_DEBIT_TRANSACTION: 'POST_DEBIT_TRANSACTION',
  POSTING_DEBIT_TRANSACTION: 'POSTING_DEBIT_TRANSACTION',
  POSTED_DEBIT_TRANSACTION: 'POSTED_DEBIT_TRANSACTION',
  POST_DEBIT_TRANSACTION_FAILED: 'POST_DEBIT_TRANSACTION_FAILED',

  //POST TRANSFER TRANSACTION
  POST_TRANSFER_TRANSACTION: 'POST_TRANSFER_TRANSACTION',
  POSTING_TRANSFER_TRANSACTION: 'POSTING_TRANSFER_TRANSACTION',
  POSTED_TRANSFER_TRANSACTION: 'POSTED_TRANSFER_TRANSACTION',
  POST_TRANSFER_TRANSACTION_FAILED: 'POST_TRANSFER_TRANSACTION_FAILED',

  // SETTLE TRANSACTION
  POST_SETTLE_TRANSACTION: 'POST_SETTLE_TRANSACTION',
  POSTED_SETTLE_TRANSACTION: 'POSTED_SETTLE_TRANSACTION',
  POSTING_SETTLE_TRANSACTION: 'POSTING_SETTLE_TRANSACTION',
  POST_SETTLE_TRANSACTION_FAILED: 'POST_SETTLE_TRANSACTION_FAILED',

  // CANCEL TRANSACTION
  POST_CANCEL_TRANSACTION: 'POST_CANCEL_TRANSACTION',
  POSTED_CANCEL_TRANSACTION: 'POSTED_CANCEL_TRANSACTION',
  POSTING_CANCEL_TRANSACTION: 'POSTING_CANCEL_TRANSACTION',
  POST_CANCEL_TRANSACTION_FAILED: 'POST_CANCEL_TRANSACTION_FAILED',


  // FETCH MERCHANT TRX QUEUE
  FETCH_TRX_QUEUE_MERCHANT: 'FETCH_TRX_QUEUE_MERCHANT',
  FETCHING_TRX_QUEUE_MERCHANT: 'FETCHING_TRX_QUEUE_MERCHANT',
  FETCHED_TRX_QUEUE_MERCHANT: 'FETCHED_TRX_QUEUE_MERCHANT',
  FETCH_TRX_QUEUE_MERCHANT_FAILED: 'FETCH_TRX_QUEUE_MERCHANT_FAILED',
  LOADING_MORE_TRX_QUEUE_MERCHANT: 'LOADING_MORE_TRX_QUEUE_MERCHANT',

  // FETCH TRX QUEUE
  FETCH_TRX_QUEUE: 'FETCH_TRX_QUEUE',
  FETCHING_TRX_QUEUE: 'FETCHING_TRX_QUEUE',
  FETCHED_TRX_QUEUE: 'FETCHED_TRX_QUEUE',
  FETCH_TRX_QUEUE_FAILED: 'FETCH_TRX_QUEUE_FAILED',

  // FETCH TRX QUEUE WALLET IDS
  FETCH_TRX_QUEUE_WALLET_ID: 'FETCH_TRX_QUEUE_WALLET_ID',
  FETCHING_TRX_QUEUE_WALLET_ID: 'FETCHING_TRX_QUEUE_WALLET_ID',
  FETCHED_TRX_QUEUE_WALLET_ID: 'FETCHED_TRX_QUEUE_WALLET_ID',
  FETCH_TRX_QUEUE_WALLET_ID_FAILED: 'FETCH_TRX_QUEUE_WALLET_ID_FAILED',

  // FILTER TRX QUEUE
  FILTER_TRX_QUEUE: 'FILTER_TRX_QUEUE',
  FILTERING_TRX_QUEUE: 'FILTERING_TRX_QUEUE',
  FILTERED_TRX_QUEUE: 'FILTERED_TRX_QUEUE',
  FILTER_TRX_QUEUE_FAILED: 'FILTER_TRX_QUEUE_FAILED',

  // FETCH SEARCH TRANSACTIONS DETAILS
  FETCH_SEARCH_TRANSACTION_DETAILS: 'FETCH_SEARCH_TRANSACTION_DETAILS',
  FETCHING_SEARCH_TRANSACTION_DETAILS: 'FETCHING_SEARCH_TRANSACTION_DETAILS',
  FETCHED_SEARCH_TRANSACTION_DETAILS: 'FETCHED_SEARCH_TRANSACTION_DETAILS',
  FETCHING_MORE_SEARCHED_TRANSACTION_DETAILS: 'FETCHING_MORE_SEARCHED_TRANSACTION_DETAILS',
  FETCH_SEARCH_TRANSACTION_DETAILS_FAILED: 'FETCH_SEARCH_TRANSACTION_DETAILS_FAILED',

  // RESET TRANSACTIONS TABLE
  RESET_TRANSACTIONS_TABLE: 'RESET_TRANSACTIONS_TABLE',
  // DOWNLOAD LOAD WITHDRAW REPORT
  DOWNLOAD_LOAD_WITHDRAW_REPORT: 'DOWNLOAD_LOAD_WITHDRAW_REPORT',
  DOWNLOADING_LOAD_WITHDRAW_REPORT: 'DOWNLOADING_LOAD_WITHDRAW_REPORT',
  DOWNLOADED_LOAD_WITHDRAW_REPORT: 'DOWNLOADED_LOAD_WITHDRAW_REPORT',
  DOWNLOAD_LOAD_WITHDRAW_FAILED: 'DOWNLOAD_LOAD_WITHDRAW_FAILED',

  DOWNLOAD_RECONCILIATION_REPORT: 'DOWNLOAD_RECONCILIATION_REPORT',
  DOWNLOADING_RECONCILIATION_REPORT: 'DOWNLOADING_RECONCILIATION_REPORT',
  DOWNLOADED_RECONCILIATION_REPORT: 'DOWNLOADED_RECONCILIATION_REPORT',
  DOWNLOAD_RECONCILIATION_REPORT_FAILED: 'DOWNLOAD_RECONCILIATION_REPORT_FAILED',

  DOWNLOAD_ADMIN_REPORT: 'DOWNLOAD_ADMIN_REPORT',
  DOWNLOADING_ADMIN_REPORT: 'DOWNLOADING_ADMIN_REPORT',
  DOWNLOADED_ADMIN_REPORT: 'DOWNLOADED_ADMIN_REPORT',
  DOWNLOAD_ADMIN_REPORT_FAILED: 'DOWNLOAD_ADMIN_REPORT_FAILED',
  CLEAR_DOWNLOAD_MESSAGE: 'CLEAR_DOWNLOAD_MESSAGE',

  FETCH_DOWNLOAD_LIST: 'FETCH_DOWNLOAD_LIST',
  FETCHING_DOWNLOAD_LIST: 'FETCHING_DOWNLOAD_LIST',
  FETCHED_DOWNLOAD_LIST: 'FETCHED_DOWNLOAD_LIST',
  FETCH_DOWNLOAD_LIST_FAILED: 'FETCH_DOWNLOAD_LIST_FAILED',

  FETCH_PREVIOUS_DOWNLOADS: 'FETCH_PREVIOUS_DOWNLOADS',
  FETCHING_PREVIOUS_DOWNLOADS: 'FETCHING_PREVIOUS_DOWNLOADS',
  FETCHED_PREVIOUS_DOWNLOADS: 'FETCHED_PREVIOUS_DOWNLOADS',
  FETCH_PREVIOUS_DOWNLOADS_FAILED: 'FETCH_PREVIOUS_DOWNLOADS_FAILED',

  SET_DOWNLOAD_REPORT_DATE: 'SET_DOWNLOAD_REPORT_DATE',

  MQTT_TRX: 'MQTT_TRX',

  CHANGE_WALLET_STATUS: 'CHANGE_WALLET_STATUS',
  CHANGING_WALLET_STATUS: 'CHANGING_WALLET_STATUS',
  CHANGED_WALLET_STATUS: 'CHANGED_WALLET_STATUS',
  CHANGE_WALLET_STATUS_FAILED: 'CHANGE_WALLET_STATUS_FAILED',

  // FUND SOURCE
  FETCH_FUND_SOURCE_GRP: 'FETCH_FUND_SOURCE_GRP',
  FETCHING_FUND_SOURCE_GRP: 'FETCHING_FUND_SOURCE_GRP',
  FETCHED_FUND_SOURCE_GRP: 'FETCHED_FUND_SOURCE_GRP',
  FETCH_FUND_SOURCE_GRP_FAILED: 'FETCH_FUND_SOURCE_GRP_FAILED',
  LOADING_MORE_FUND_SOURCE_GRP: 'LOADING_MORE_FUND_SOURCE_GRP',

  // PREDICTIVE BALANCE
  FETCH_PREDICTIVE_BALANCE_GRP: 'FETCH_PREDICTIVE_BALANCE_GRP',
  FETCHING_PREDICTIVE_BALANCE_GRP: 'FETCHING_PREDICTIVE_BALANCE_GRP',
  FETCHED_PREDICTIVE_BALANCE_GRP: 'FETCHED_PREDICTIVE_BALANCE_GRP',
  FETCH_PREDICTIVE_BALANCE_GRP_FAILED: 'FETCH_PREDICTIVE_BALANCE_GRP_FAILED',
  LOADING_MORE_PREDICTIVE_BALANCE_GRP: 'LOADING_MORE_PREDICTIVE_BALANCE_GRP',

  // PREDICTIVE BALANCE BANK ACCOUNT
  FETCH_PB_BA: 'FETCH_PB_BA',
  FETCHING_PB_BA: 'FETCHING_PB_BA',
  FETCHED_PB_BA: 'FETCHED_PB_BA',
  FETCH_PB_BA_FAILED: 'FETCH_PB_BA_FAILED',

  // PREDICTIVE BALANCE 90 DAYS
  FETCH_PB_90DAYS: 'FETCH_PB_90DAYS',
  FETCHING_PB_90DAYS: 'FETCHING_PB_90DAYS',
  FETCHED_PB_90DAYS: 'FETCHED_PB_90DAYS',
  FETCH_PB_90DAYS_FAILED: 'FETCH_PB_90DAYS_FAILED',

  FETCH_BANK_ACCOUNTS: 'FETCH_BANK_ACCOUNTS',
  FETCHING_BANK_ACCOUNTS: 'FETCHING_BANK_ACCOUNTS',
  FETCHED_BANK_ACCOUNTS: 'FETCHED_BANK_ACCOUNTS',
  FETCH_BANK_ACCOUNTS_FAILED: 'FETCH_BANK_ACCOUNTS_FAILED',

  ADD_FUND_SOURCE: 'ADD_FUND_SOURCE',
  ADDING_FUND_SOURCE: 'ADDING_FUND_SOURCE',
  ADDED_FUND_SOURCE: 'ADDED_FUND_SOURCE',
  ADD_FUND_SOURCE_FAILED: 'ADD_FUND_SOURCE_FAILED',

  EDIT_FUND_SOURCE: 'EDIT_FUND_SOURCE',
  EDITING_FUND_SOURCE: 'EDITING_FUND_SOURCE',
  EDITED_FUND_SOURCE: 'EDITED_FUND_SOURCE',
  EDIT_FUND_SOURCE_FAILED: 'EDIT_FUND_SOURCE_FAILED',

  DELETE_FUND_SOURCE: 'DELETE_FUND_SOURCE',
  DELETING_FUND_SOURCE: 'DELETING_FUND_SOURCE',
  DELETED_FUND_SOURCE: 'DELETED_FUND_SOURCE',
  DELETE_FUND_SOURCE_FAILED: 'DELETE_FUND_SOURCE_FAILED',

  DEACTIVATE_FUND_SOURCE: 'DEACTIVATE_FUND_SOURCE',
  DEACTIVATING_FUND_SOURCE: 'DEACTIVATING_FUND_SOURCE',
  DEACTIVATED_FUND_SOURCE: 'DEACTIVATED_FUND_SOURCE',
  DEACTIVATE_FUND_SOURCE_FAILED: 'DEACTIVATE_FUND_SOURCE_FAILED',

  // FETCH BANK ACCOUNT BY ACCT NO
  FETCH_BANK_ACCOUNT_BY_ACCT_NO: 'FETCH_BANK_ACCOUNT_BY_ACCT_NO',
  FETCHING_BANK_ACCOUNT_BY_ACCT_NO: 'FETCHING_BANK_ACCOUNT_BY_ACCT_NO',
  FETCHED_BANK_ACCOUNT_BY_ACCT_NO: 'FETCHED_BANK_ACCOUNT_BY_ACCT_NO',
  FETCH_BANK_ACCOUNT_BY_ACCT_NO_FAILED: 'FETCH_BANK_ACCOUNT_BY_ACCT_NO_FAILED',


  // CRUD BANK ACCOUNT

  ADD_BANK_ACCOUNT: 'ADD_BANK_ACCOUNT',
  ADDING_BANK_ACCOUNT: 'ADDING_BANK_ACCOUNT',
  ADDED_BANK_ACCOUNT: 'ADDED_BANK_ACCOUNT',
  ADD_BANK_ACCOUNT_FAILED: 'ADD_BANK_ACCOUNT_FAILED',

  EDIT_BANK_ACCOUNT: 'EDIT_BANK_ACCOUNT',
  EDITING_BANK_ACCOUNT: 'EDITING_BANK_ACCOUNT',
  EDITED_BANK_ACCOUNT: 'EDITED_BANK_ACCOUNT',
  EDIT_BANK_ACCOUNT_FAILED: 'EDIT_BANK_ACCOUNT_FAILED',

  DELETE_BANK_ACCOUNT: 'DELETE_BANK_ACCOUNT',
  DELETING_BANK_ACCOUNT: 'DELETING_BANK_ACCOUNT',
  DELETED_BANK_ACCOUNT: 'DELETED_BANK_ACCOUNT',
  DELETE_BANK_ACCOUNT_FAILED: 'DELETE_BANK_ACCOUNT_FAILED',

  DEACTIVATE_BANK_ACCOUNT: 'DEACTIVATE_BANK_ACCOUNT',
  DEACTIVATING_BANK_ACCOUNT: 'DEACTIVATING_BANK_ACCOUNT',
  DEACTIVATED_BANK_ACCOUNT: 'DEACTIVATED_BANK_ACCOUNT',
  DEACTIVATE_BANK_ACCOUNT_FAILED: 'DEACTIVATE_BANK_ACCOUNT_FAILED',

  //FETCH LOOKUPS CURRENCY
  FETCH_LOOKUPS_BANK: 'FETCH_LOOKUPS_BANK',
  FETCHED_LOOKUPS_BANK: 'FETCHED_LOOKUPS_BANK',
  FETCHING_LOOKUPS_BANK: 'FETCHING_LOOKUPS_BANK',
  FETCH_LOOKUPS_BANK_FAILED: 'FETCH_LOOKUPS_BANK_FAILED',

  // RESET BANK ACCOUNTS
  RESET_BANK_ACCOUNTS: 'RESET_BANK_ACCOUNTS',
  RESET_EDIT_BANK_ACCOUNTS: 'RESET_EDIT_BANK_ACCOUNTS',
  RESET_DEACTIVATE_BANK_ACCOUNTS: 'RESET_DEACTIVATE_BANK_ACCOUNTS',
  RESET_DELETE_BANK_ACCOUNTS: 'RESET_DELETE_BANK_ACCOUNTS',

  // RESET ALL WALLET MODULE
  RESET_ALL_WALLET_DETAILS: 'RESET_ALL_WALLET_DETAILS',

  // FETCH SEARCH MERCHANT TRANSACTIONS DETAILS
  FETCH_SEARCH_MERCHANT_TRANSACTION_DETAILS: 'FETCH_SEARCH_MERCHANT_TRANSACTION_DETAILS',
  FETCHING_SEARCH_MERCHANT_TRANSACTION_DETAILS: 'FETCHING_SEARCH_MERCHANT_TRANSACTION_DETAILS',
  FETCHED_SEARCH_MERCHANT_TRANSACTION_DETAILS: 'FETCHED_SEARCH_MERCHANT_TRANSACTION_DETAILS',
  FETCHING_MORE_SEARCHED_MERCHANT_TRANSACTION_DETAILS: 'FETCHING_MORE_SEARCHED_MERCHANT_TRANSACTION_DETAILS',
  FETCH_SEARCH_MERCHANT_TRANSACTION_DETAILS_FAILED: 'FETCH_SEARCH_MERCHANT_TRANSACTION_DETAILS_FAILED',

  SEARCH_WALLET_DETAILS: 'SEARCH_WALLET_DETAILS',
  SEARCHING_WALLET_DETAILS: 'SEARCHING_WALLET_DETAILS',
  SEARCHED_WALLET_DETAILS: 'SEARCHED_WALLET_DETAILS',
  SEARCH_WALLET_DETAILS_FAILED: 'SEARCH_WALLET_DETAILS_FAILED',

  // SEARCH WITHHELD TRANSACTIONS DATE RANGE
  SEARCH_WITHHELD_TRANSACTIONS: 'SEARCH_WITHHELD_TRANSACTIONS',
  SEARCHING_WITHHELD_TRANSACTIONS: 'SEARCHING_WITHHELD_TRANSACTIONS',
  SEARCHED_WITHHELD_TRANSACTIONS: 'SEARCHED_WITHHELD_TRANSACTIONS',
  SEARCH_WITHHELD_TRANSACTIONS_FAILED: 'SEARCH_WITHHELD_TRANSACTIONS_FAILED',

  FETCH_INTEGRATION_ROLE: 'FETCH_INTEGRATION_ROLE',
  FETCHED_INTEGRATION_ROLE: 'FETCHED_INTEGRATION_ROLE',
  FETCHING_INTEGRATION_ROLE: 'FETCHING_INTEGRATION_ROLE',
  FETCHING_INTEGRATION_ROLE_FAILED: 'FETCHING_INTEGRATION_ROLE_FAILED',

  FETCH_INTEGRATION_ROLES_PRIVILEGES: 'FETCH_INTEGRATION_ROLES_PRIVILEGES',
  FETCHED_INTEGRATION_ROLES_PRIVILEGES: 'FETCHED_INTEGRATION_ROLES_PRIVILEGES',
  FETCHING_INTEGRATION_ROLES_PRIVILEGES: 'FETCHING_INTEGRATION_ROLES_PRIVILEGES',
  FETCHING_INTEGRATION_ROLES_PRIVILEGES_FAILED: 'FETCHING_INTEGRATION_ROLES_PRIVILEGES_FAILED',

  UPDATE_INTEGRATION_ROLES_PRIVILEGES: 'UPDATE_INTEGRATION_ROLES_PRIVILEGES',
  UPDATING_INTEGRATION_ROLES_PRIVILEGES: 'UPDATING_INTEGRATION_ROLES_PRIVILEGES',
  UPDATED_INTEGRATION_ROLES_PRIVILEGES: 'UPDATED_INTEGRATION_ROLES_PRIVILEGES',
  UPDATING_INTEGRATION_ROLES_PRIVILEGES_FAILED: 'UPDATING_INTEGRATION_ROLES_PRIVILEGES_FAILED',

  ADD_INTEGRATION_ROLES_PRIVILEGE: 'ADD_INTEGRATION_ROLES_PRIVILEGE',
  ADDING_INTEGRATION_ROLES_PRIVILEGE: 'ADDING_INTEGRATION_ROLES_PRIVILEGE',
  ADDED_INTEGRATION_ROLES_PRIVILEGE: 'ADDED_INTEGRATION_ROLES_PRIVILEGE',
  ADDING_INTEGRATION_ROLES_PRIVILEGE_FAILED: 'ADDING_INTEGRATION_ROLES_PRIVILEGE_FAILED',
  RESET_ADD_INTEGRATION_ROLES_PRIVILEGE: 'RESET_ADD_INTEGRATION_ROLES_PRIVILEGE',

  FETCH_INTEGRATORS_ADMIN_ACCESS: 'FETCH_INTEGRATORS_ADMIN_ACCESS',
  FETCHED_INTEGRATORS_ADMIN_ACCESS: 'FETCHED_INTEGRATORS_ADMIN_ACCESS',
  FETCHING_INTEGRATORS_ADMIN_ACCESS: 'FETCHING_INTEGRATORS_ADMIN_ACCESS',
  FETCHING_INTEGRATORS_ADMIN_ACCESS_FAILED: 'FETCHING_INTEGRATORS_ADMIN_ACCESS_FAILED',


  // FETCH ALL MERCHANTS
  FETCH_MERCHANTS: 'FETCH_MERCHANTS',
  FETCHING_MERCHANTS: 'FETCHING_MERCHANTS',
  FETCHED_MERCHANTS: 'FETCHED_MERCHANTS',
  FETCH_MERCHANTS_FAILED: 'FETCH_MERCHANTS_FAILED',

  // SEARCH MERCHANT
  SEARCH_MERCHANT: 'SEARCH_MERCHANT',
  SEARCH_MERCHANT_FAILED: 'SEARCH_MERCHANT_FAILED',
  SEARCHING_MERCHANT: 'SEARCHING_MERCHANT',
  SEARCHED_MERCHANT: 'SEARCHED_MERCHANT',

  // FETCH WALLET DETAILS
  FETCH_CORP_WALLETS_DETAILS: 'FETCH_CORP_WALLETS_DETAILS',
  FETCHING_CORP_WALLETS_DETAILS: 'FETCHING_CORP_WALLETS_DETAILS',
  FETCHED_CORP_WALLETS_DETAILS: 'FETCHED_CORP_WALLETS_DETAILS',
  FETCH_CORP_WALLETS_DETAILS_FAILED: 'FETCH_CORP_WALLETS_DETAILS_FAILED',
  RESET_CORP_WALLETS_DETAILS: 'RESET_CORP_WALLETS_DETAILS',

  // FETCH CASH OUT TRANSACTIONS
  FETCH_CASH_OUT_TRANSACTIONS: 'FETCH_CASH_OUT_TRANSACTIONS',
  FETCHING_CASH_OUT_TRANSACTIONS: 'FETCHING_CASH_OUT_TRANSACTIONS',
  FETCHED_CASH_OUT_TRANSACTIONS: 'FETCHED_CASH_OUT_TRANSACTIONS',
  FETCH_CASH_OUT_TRANSACTIONS_FAILED: 'FETCH_CASH_OUT_TRANSACTIONS_FAILED',
  LOADMORE_CASH_OUT_TRANSACTIONS: 'LOADMORE_CASH_OUT_TRANSACTIONS',
  // RESET_CASH_OUT_TRANSACTIONS: 'RESET_CASH_OUT_TRANSACTIONS',

  // FETCH CASH IN TRANSACTIONS
  FETCH_CASH_IN_TRANSACTIONS: 'FETCH_CASH_IN_TRANSACTIONS',
  FETCHING_CASH_IN_TRANSACTIONS: 'FETCHING_CASH_IN_TRANSACTIONS',
  FETCHED_CASH_IN_TRANSACTIONS: 'FETCHED_CASH_IN_TRANSACTIONS',
  FETCH_CASH_IN_TRANSACTIONS_FAILED: 'FETCH_CASH_IN_TRANSACTIONS_FAILED',
  LOADMORE_CASH_IN_TRANSACTIONS: 'LOADMORE_CASH_IN_TRANSACTIONS',

  // FETCH AIRTIME LOAD TRANSACTIONS
  FETCH_AIRTIME_LOAD_TRANSACTIONS: 'FETCH_AIRTIME_LOAD_TRANSACTIONS',
  FETCHING_AIRTIME_LOAD_TRANSACTIONS: 'FETCHING_AIRTIME_LOAD_TRANSACTIONS',
  FETCHED_AIRTIME_LOAD_TRANSACTIONS: 'FETCHED_AIRTIME_LOAD_TRANSACTIONS',
  FETCH_AIRTIME_LOAD_TRANSACTIONS_FAILED: 'FETCH_AIRTIME_LOAD_TRANSACTIONS_FAILED',
  LOADMORE_AIRTIME_LOAD_TRANSACTIONS: 'LOADMORE_AIRTIME_LOAD_TRANSACTIONS',

  // FETCH AIRTIME LOAD TRANSACTIONS
  FETCH_BILLS_PAYMENT_TRANSACTIONS: 'FETCH_BILLS_PAYMENT_TRANSACTIONS',
  FETCHING_BILLS_PAYMENT_TRANSACTIONS: 'FETCHING_BILLS_PAYMENT_TRANSACTIONS',
  FETCHED_BILLS_PAYMENT_TRANSACTIONS: 'FETCHED_BILLS_PAYMENT_TRANSACTIONS',
  FETCH_BILLS_PAYMENT_TRANSACTIONS_FAILED: 'FETCH_BILLS_PAYMENT_TRANSACTIONS_FAILED',
  LOADMORE_BILLS_PAYMENT_TRANSACTIONS: 'LOADMORE_BILLS_PAYMENT_TRANSACTIONS',

  // FETCH FUND TRANSFER TRANSACTIONS
  FETCH_FUND_TRANSFER_TRANSACTIONS: 'FETCH_FUND_TRANSFER_TRANSACTIONS',
  FETCHING_FUND_TRANSFER_TRANSACTIONS: 'FETCHING_FUND_TRANSFER_TRANSACTIONS',
  FETCHED_FUND_TRANSFER_TRANSACTIONS: 'FETCHED_FUND_TRANSFER_TRANSACTIONS',
  FETCH_FUND_TRANSFER_TRANSACTIONS_FAILED: 'FETCH_FUND_TRANSFER_TRANSACTIONS_FAILED',
  LOADMORE_FUND_TRANSFER_TRANSACTIONS: 'LOADMORE_FUND_TRANSFER_TRANSACTIONS',

  // SET TRANSACTION TYPE
  SET_TRANSACTION_TYPE: 'SET_TRANSACTION_TYPE',

  // RESET LOADMORE TRANSACTION
  RESET_LOAD_MORE_TRANSACTION: 'RESET_LOAD_MORE_TRANSACTION',

  // DOWNLOAD TRX REPORT
  DOWNLOAD_REPORT: 'DOWNLOAD_REPORT',
  DOWNLOADING_REPORT: 'DOWNLOADING_REPORT',
  DOWNLOADED_REPORT: 'DOWNLOADED_REPORT',
  DOWNLOAD_REPORT_FAILED: 'DOWNLOAD_REPORT_FAILED',
  RESET_DOWNLOAD_REPORT: 'RESET_DOWNLOAD_REPORT',

  // FETCH TRX REPORT
  FETCH_DOWNLOAD_REPORT: 'FETCH_DOWNLOAD_REPORT',
  FETCH_DOWNLOAD_REPORT_FAILED: 'FETCH_DOWNLOAD_REPORT_FAILED',
  FETCHING_DOWNLOAD_REPORT: 'FETCHING_DOWNLOAD_REPORT',
  FETCHED_DOWNLOAD_REPORT: 'FETCHED_DOWNLOAD_REPORT',
  FETCH_DOWNLOAD_REPORT_RESET: 'FETCH_DOWNLOAD_REPORT_RESET',

  // FETCH TRANSACTION LIST
  FETCH_TRANSACTION_LIST: 'FETCH_TRANSACTION_LIST',
  FETCHING_TRANSACTION_LIST: 'FETCHING_TRANSACTION_LIST',
  FETCHING_MORE_TRANSACTION_LIST: 'FETCHING_MORE_TRANSACTION_LIST',
  FETCHED_TRANSACTION_LIST: 'FETCHED_TRANSACTION_LIST',
  FETCH_TRANSACTION_LIST_FAILED: 'FETCH_TRANSACTION_LIST_FAILED',
  FETCH_TRANSACTION_LIST_RESET: 'FETCH_TRANSACTION_LIST_RESET',
  RESET_TRANSACTION_LIST: 'RESET_TRANSACTION_LIST',

  // FETCH TRANSACTION DETAILS
  FETCH_TRANSACTION_DETAILS: 'FETCH_TRANSACTION_DETAILS',
  FETCHING_TRANSACTION_DETAILS: 'FETCHING_TRANSACTION_DETAILS',
  FETCHED_TRANSACTION_DETAILS: 'FETCHED_TRANSACTION_DETAILS',
  FETCH_TRANSACTION_DETAILS_FAILED: 'FETCH_TRANSACTION_DETAILS_FAILED',
  RESET_TRANSACTION_DETAILS: 'RESET_TRANSACTION_DETAILS',

  // FETCH DOWNLOAD LIST
  FETCH_EXCEL_DOWNLOAD_LIST: 'FETCH_EXCEL_DOWNLOAD_LIST',
  FETCHING_EXCEL_DOWNLOAD_LIST: 'FETCHING_EXCEL_DOWNLOAD_LIST',
  FETCHED_EXCEL_DOWNLOAD_LIST: 'FETCHED_EXCEL_DOWNLOAD_LIST',
  FETCH_EXCEL_DOWNLOAD_LIST_FAILED: 'FETCH_EXCEL_DOWNLOAD_LIST_FAILED',

  // DOWNLOAD CSV TRANSACTION
  DOWNLOAD_JOURNAL_CSV: 'DOWNLOAD_JOURNAL_CSV',
  DOWNLOADING_JOURNAL_CSV: 'DOWNLOADING_JOURNAL_CSV',
  DOWNLOADED_JOURNAL_CSV: 'DOWNLOADED_JOURNAL_CSV',
  DOWNLOD_FAILED_JOURNAL_CSV: 'DOWNLOD_FAILED_JOURNAL_CSV',
  CLEAR_DOWNLOAD_JOURNAL_CSV: 'CLEAR_DOWNLOAD_JOURNAL_CSV',

  // FETCH PREVIOUS JE DOWNLOADED REPORTS
  FETCH_PREVIOUS_DOWNLOADS_NEW: 'FETCH_PREVIOUS_DOWNLOADS_NEW',
  FETCHING_PREVIOUS_DOWNLOADS_NEW: 'FETCHING_PREVIOUS_DOWNLOADS_NEW',
  FETCHED_PREVIOUS_DOWNLOADS_NEW: 'FETCHED_PREVIOUS_DOWNLOADS_NEW',
  FETCH_PREVIOUS_DOWNLOADS_NEW_FAILED: 'FETCH_PREVIOUS_DOWNLOADS_NEW_FAILED',

  SET_IS_FILTERED: 'SET_IS_FILTERED',

  DOWNLOAD_STATUS: 'DOWNLOAD_STATUS',

  MANUAL_CREDIT: 'MANUAL_CREDIT',
  MANUAL_CREDIT_ADDING: 'MANUAL_CREDIT_ADDING',
  MANUAL_CREDIT_ADDED: 'MANUAL_CREDIT_ADDED',
  RESET_MANUAL_CREDIT: 'RESET_MANUAL_CREDIT',
  MANUAL_CREDIT_FAILED: 'MANUAL_CREDIT_FAILED',

  GENERATE_MANUAL_CREDIT_OTP: 'GENERATE_MANUAL_CREDIT_OTP',
  GENERATING_MANUAL_CREDIT_OTP: 'GENERATING_MANUAL_CREDIT_OTP',
  GENERATED_MANUAL_CREDIT_OTP: 'GENERATED_MANUAL_CREDIT_OTP',
  GENERATE_MANUAL_CREDIT_OTP_RESET: 'GENERATE_MANUAL_CREDIT_OTP_RESET',
  SET_LOADING: 'SET_LOADING',

  RESEND_MANUAL_CREDIT_OTP: 'RESEND_MANUAL_CREDIT_OTP',
  RESENDING_MANUAL_CREDIT_OTP: 'RESENDING_MANUAL_CREDIT_OTP',
  RESEND_MANUAL_CREDIT_OTP_SUCCESS: 'RESEND_MANUAL_CREDIT_OTP_SUCCESS',
  RESEND_MANUAL_CREDIT_OTP_FAILED: 'RESEND_MANUAL_CREDIT_OTP_FAILED',
  RESEND_MANUAL_CREDIT_OTP_RESET: 'RESEND_MANUAL_CREDIT_OTP_RESET',

  NEW_WALLET: 'NEW_WALLET',
  ADDING_NEW_WALLET: 'ADDING_NEW_WALLET',
  ADDED_NEW_WALLET: 'ADDED_NEW_WALLET',
  RESET_NEW_WALLET: 'RESET_NEW_WALLET',
  ADD_NEW_WALLET_FAILED: 'ADD_NEW_WALLET_FAILED',

  NEW_FEE_WALLET: 'NEW_FEE_WALLET',
  ADDING_NEW_FEE_WALLET: 'ADDING_NEW_FEE_WALLET',
  ADDED_NEW_FEE_WALLET: 'ADDED_NEW_FEE_WALLET',
  RESET_NEW_FEE_WALLET: 'RESET_NEW_FEE_WALLET',
  ADD_NEW_FEE_WALLET_FAILED: 'ADD_NEW_FEE_WALLET_FAILED',

  // FETCH SUPPORTED BANKS
  FETCH_INSTAPAY_BANKS: 'FETCH_INSTAPAY_BANKS',
  FETCHING_INSTAPAY_BANKS: 'FETCHING_INSTAPAY_BANKS',
  FETCHED_INSTAPAY_BANKS: 'FETCHED_INSTAPAY_BANKS',
  FETCHED_INSTAPAY_BANKS_FAILED: 'FETCHED_INSTAPAY_BANKS_FAILED',

  // GENERATE OTP FOR CASH OUT
  GENERATE_CASHOUT_OTP: 'GENERATE_CASHOUT_OTP',
  GENERATING_CASHOUT_OTP: 'GENERATING_CASHOUT_OTP',
  GENERATED_CASHOUT_OTP: 'GENERATED_CASHOUT_OTP',
  GENERATE_CASHOUT_OTP_FAILED: 'GENERATE_CASHOUT_OTP_FAILED',
  GENERATE_CASHOUT_OTP_RESET: 'GENERATE_CASHOUT_OTP_RESET',


   // GENERATE RESEND OTP FOR CASH OUT
  RESEND_CASHOUT_OTP: 'RESEND_CASHOUT_OTP',
  RESENDING_CASHOUT_OTP: 'RESENDING_CASHOUT_OTP',
  RESEND_CASHOUT_OTP_SUCCESS: 'RESEND_CASHOUT_OTP_SUCCESS',
  RESEND_CASHOUT_OTP_FAILED: 'RESEND_CASHOUT_OTP_FAILED',
  RESEND_CASHOUT_OTP_RESET: 'RESEND_CASHOUT_OTP_RESET',

  // CASH OUT VIA INSTAPAY
  CASH_OUT: 'CASH_OUT',
  CASHING_OUT: 'CASHING_OUT',
  CASHED_OUT: 'CASHED_OUT',
  RESET_CASH_OUT: 'RESET_CASH_OUT',
  CASH_OUT_FAILED: 'CASH_OUT_FAILED',
  // FETCH CASHIN TRANSACTION DETAILS
  FETCH_CASHIN_TRANSACTION_DETAILS: 'FETCH_CASHIN_TRANSACTION_DETAILS',
  FETCHING_CASHIN_TRANSACTION_DETAILS: 'FETCHING_CASHIN_TRANSACTION_DETAILS',
  FETCHED_CASHIN_TRANSACTION_DETAILS: 'FETCHED_CASHIN_TRANSACTION_DETAILS',
  FETCH_CASHIN_TRANSACTION_DETAILS_FAILED: 'FETCH_CASHIN_TRANSACTION_DETAILS_FAILED',
  RESET_CASHIN_TRANSACTION_DETAILS: 'RESET_CASHIN_TRANSACTION_DETAILS',

  // FETCH CASH OUT TRANSACTION DETAILS
  FETCH_CASHOUT_TRANSACTION_DETAILS: 'FETCH_CASHOUT_TRANSACTION_DETAILS',
  FETCHING_CASHOUT_TRANSACTION_DETAILS: 'FETCHING_CASHOUT_TRANSACTION_DETAILS',
  FETCHED_CASHOUT_TRANSACTION_DETAILS: 'FETCHED_CASHOUT_TRANSACTION_DETAILS',
  FETCH_CASHOUT_TRANSACTION_DETAILS_FAILED: 'FETCH_CASHOUT_TRANSACTION_DETAILS_FAILED',
  RESET_CASHOUT_TRANSACTION_DETAILS: 'RESET_CASHOUT_TRANSACTION_DETAILS',

  // FETCH WALLET DOWNLOADED REPORT
  fetchDownloadReport: (payload) => ({
    type: actions.FETCH_DOWNLOAD_REPORT,
    payload: payload,
  }),

  // RESET JOURNAL ENTRY FETCH DOWNLOADED REPORT
  resetFetchDownloadReport: () => ({
    type: actions.FETCH_DOWNLOAD_REPORT_RESET,
  }),

  // GET DOWNLOAD LIST
  getExcelDownloadList: (payload) => ({
    type: actions.FETCH_EXCEL_DOWNLOAD_LIST,
    payload: payload,
  }),

  // RESET DOWNLOAD STATUS
  resetDownloadStatus: () => ({
    type: actions.DOWNLOAD_STATUS,
    payload: null,
  }),

  // FETCH ALL MERCHANTS
  fetchMerchants: (payload) => ({
    type: actions.FETCH_MERCHANTS,
    payload
  }),

  // SEARCH MERCHANT
  searchMerchant: (payload) => ({
    type: actions.SEARCH_MERCHANT,
    payload,
  }),

  // FETCH WALLET DETAILS
  fetchWalletDetails: (payload) => ({
    type: actions.FETCH_CORP_WALLETS_DETAILS,
    payload: payload,
  }),

  // FETCH CASH OUT TRANSACTIONS
  fetchCashOutTransactions: (payload) => ({
    type: actions.FETCH_CASH_OUT_TRANSACTIONS,
    payload,
  }),

  // RESET CASH OUT TRANSACTIONS
  // resetCashOutTransactions: (payload) => ({
  //   type: actions.RESET_CASH_OUT_TRANSACTIONS,
  //   payload,
  // }),

  // FETCH CASH IN TRANSACTIONS
  fetchCashInTransactions: (payload) => ({
    type: actions.FETCH_CASH_IN_TRANSACTIONS,
    payload,
  }),

  // FETCH AIRTIME LOAD TRANSACTIONS
  fetchAirtimeLoadTransactions: (payload) => ({
    type: actions.FETCH_AIRTIME_LOAD_TRANSACTIONS,
    payload,
  }),

  // FETCH BILLS PAYMENT TRANSACTIONS
  fetchBillsPaymentTransactions: (payload) => ({
    type: actions.FETCH_BILLS_PAYMENT_TRANSACTIONS,
    payload,
  }),

  // FETCH FUND TRANSFER TRANSACTIONS
  fetchFundTransferTransactions: (payload) => ({
    type: actions.FETCH_FUND_TRANSFER_TRANSACTIONS,
    payload,
  }),

  // RESET LOAD MORE TRANSACTION
  resetLoadMoreTransaction: () => ({
    type: actions.RESET_LOAD_MORE_TRANSACTION,
  }),

  // SET TRANSACTION TYPE
  setTransactionType: (payload) => ({
    type: actions.SET_TRANSACTION_TYPE,
    payload,
  }),

  // DOWNLOAD TRX REPORT
  downloadReport: (payload) => ({
    type: actions.DOWNLOAD_REPORT,
    payload: payload,
  }),

  // RESET DOWNLOAD TRX REPORT
  resetDownloadReport: () => ({
    type: actions.RESET_DOWNLOAD_REPORT,
  }),

  // FETCH ALL TRANSACTIONS
  fetchTransactions: (payload) => ({
    type: actions.FETCH_TRANSACTION_LIST,
    payload,
  }),

  // RESET TRANSACTION LIST
  resetTrxList: () => ({
    type: actions.RESET_TRANSACTION_LIST,
  }),

  // GET TRANSACTION DETAILS
  getTrxDetails: (payload) => ({
    type: actions.FETCH_TRANSACTION_DETAILS,
    payload: payload,
  }),

  // RESET TRANSACTION DETAILS
  resetTrxDetails: () => ({
    type: actions.RESET_TRANSACTION_DETAILS,
  }),

  // DOWNLOAD JOURNAL ENTRY REPORT
  downloadCsv: (payload) => ({
    type: actions.DOWNLOAD_JOURNAL_CSV,
    payload: payload,
  }),

  // CLEAR JOURNAL ENTRY REPORT
  clearDownloadCsv: () => ({
    type: actions.CLEAR_DOWNLOAD_JOURNAL_CSV,
  }),

  resetWalletDetails: () => ({
    type: actions.RESET_CORP_WALLETS_DETAILS,
  }),

  fetchIntegratorsAdminAccess: (payload) => ({
    type: actions.FETCH_INTEGRATORS_ADMIN_ACCESS,
    payload: payload,
  }),
  addIntegRolesPreviledges: (payload) => ({
    type: actions.ADD_INTEGRATION_ROLES_PRIVILEGE,
    payload: payload,
  }),
  updateIntegRolesPreviledges: (payload) => ({
    type: actions.UPDATE_INTEGRATION_ROLES_PRIVILEGES,
    payload: payload,
  }),
  fetchIntegRoles: () => ({
    type: actions.FETCH_INTEGRATION_ROLE,
  }),
  fetchIntegRolesPreviledges: (payload) => ({
    type: actions.FETCH_INTEGRATION_ROLES_PRIVILEGES,
    payload: payload,
  }),
  searchWalletDetails: (payload) => ({
    type: actions.SEARCH_WALLET_DETAILS,
    payload: payload,
  }),
  resetAllWalletDetails: () => ({
    type: actions.RESET_ALL_WALLET_DETAILS,
  }),
  resetAddPrivilege: () => ({
    type: actions.RESET_ADD_INTEGRATION_ROLES_PRIVILEGE,
  }),
  // FUND SOURCE
  getFundSource: (payload) => ({
    type: actions.FETCH_FUND_SOURCE_GRP,
    payload: payload,
  }),
  // PREDICTIVE BALANCE
  getPredictiveBalance: (payload) => ({
    type: actions.FETCH_PREDICTIVE_BALANCE_GRP,
    payload: payload,
  }),
  getPbBankAccountData: (payload) => ({
    type: actions.FETCH_PB_BA,
    payload: payload,
  }),
  getPbPast90Days: (payload) => ({
    type: actions.FETCH_PB_90DAYS,
    payload: payload,
  }),

  getBankAccounts: () => ({
    type: actions.FETCH_BANK_ACCOUNTS,
    // payload: payload,
  }),

  addFundSource: (payload) => ({
    type: actions.ADD_FUND_SOURCE,
    payload: payload,
  }),

  editFundSource: (payload) => ({
    type: actions.EDIT_FUND_SOURCE,
    payload: payload,
  }),

  deleteFundSource: (payload) => ({
    type: actions.DELETE_FUND_SOURCE,
    payload: payload,
  }),

  deactivateFundSource: (payload) => ({
    type: actions.DEACTIVATE_FUND_SOURCE,
    payload: payload,
  }),


  // TRANSACTION QUEUE
  getTrxQueueMerchant: (payload) => ({
    type: actions.FETCH_TRX_QUEUE_MERCHANT,
    payload: payload,
  }),

  getTrxQueue: (payload) => ({
    type: actions.FETCH_TRX_QUEUE,
    payload: payload,
  }),

  getTrxQueueWalletId: (payload) => ({
    type: actions.FETCH_TRX_QUEUE_WALLET_ID,
    payload: payload,
  }),

  filterTrxQueue: (payload) => ({
    type: actions.FILTER_TRX_QUEUE,
    payload: payload,
  }),

  // TRANSACTION DOWNLOAD REPORT
  downloadWithdrawalLoadReport: (payload) => ({
    type: actions.DOWNLOAD_LOAD_WITHDRAW_REPORT,
    payload: payload,
  }),

  downloadReconReport: (payload) => ({
    type: actions.DOWNLOAD_RECONCILIATION_REPORT,
    payload: payload,
  }),
  downloadAdminReport: (payload) => ({
    type: actions.DOWNLOAD_ADMIN_REPORT,
    payload: payload,
  }),
  clearDownloadMessage: () => ({
    type: actions.CLEAR_DOWNLOAD_MESSAGE,
  }),

  getDownloadList: () => ({
    type: actions.FETCH_DOWNLOAD_LIST,
    // payload: payload,
  }),

  getPreviousDownloads: () => ({
    type: actions.FETCH_PREVIOUS_DOWNLOADS,
    // payload: payload,
  }),

  getPreviousDownloadsNew: (payload) => ({
    type: actions.FETCH_PREVIOUS_DOWNLOADS_NEW,
    payload: payload,
  }),

  messageArrived: (payload) => ({
    type: actions.MQTT_TRX,
    payload: payload,
  }),

  setDownloadReportDate: (downloadReportDates) => ({
    type: actions.SET_DOWNLOAD_REPORT_DATE,
    downloadReportDates,
  }),


  // AUTH MANAGEMENT
  fetchAuthManagementRole: () => ({
    type: actions.FETCH_AUTH_MANAGEMENT_ROLE,
  }),
  saveAuthManagement: (payload) => ({
    type: actions.SAVE_AUTH_MANAGEMENT,
    payload: payload,
  }),
  resetSaveAuthManagement: () => ({
    type: actions.RESET_SAVE_AUTH_MANAGEMENT,
  }),
  addAuthRole: (payload) => ({
    type: actions.ADD_AUTH_ROLE,
    payload: payload,
  }),
  resetAddAuthRole: () => ({
    type: actions.RESET_ADD_AUTH_ROLE,
  }),
  // FETCH TOTAL TRANSACTIONS
  fetchTotalTransactions: (payload) => ({
    type: actions.FETCH_TOTAL_TRANSACTIONS,
    payload: payload,
  }),
  // FETCH ALL TRANSACTIONS
  fetchAllTransactions: (payload) => ({
    type: actions.FETCH_ALL_TRANSACTIONS,
    payload: payload,
  }),
  // WITHHELD TRANSACTIONS
  fetchWithheldTransactions: (payload) => ({
    type: actions.FETCH_WITHHELD_TRANSACTIONS,
    payload: payload,
  }),
  searchWithheldTransactions: (payload) => ({
    type: actions.SEARCH_WITHHELD_TRANSACTIONS,
    payload: payload,
  }),
  // FETCH CORP WALLETS BY MID
  fetchCorpWalletsByMid: (payload) => ({
    type: actions.FETCH_CORP_WALLETS_BY_MID,
    payload: payload,
  }),

  // FETCH TRANSACTIONS COUNT
  fetchTransactionsCount: (payload) => ({
    type: actions.FETCH_TRANSACTIONS_COUNT,
    payload: payload,
  }),

  // FETCH ALL TRANSACTIONS COUNT
  fetchAllTransactionsCount: (payload) => ({
    type: actions.FETCH_ALL_TRANSACTIONS_COUNT,
    payload: payload,
  }),

  // FETCH ALL TRANSACTIONS DETAILS
  fetchAllTransactionDetails: (payload) => ({
    type: actions.FETCH_ALL_TRANSACTION_DETAILS,
    payload: payload,
  }),

  // FETCH BANK DETAILS BY TRANSACTIONS ID
  fetchBankDetailsByTranxId: (payload) => ({
    type: actions.FETCH_BANK_DETAILS_BY_TRANSACTION_ID,
    payload: payload,
  }),

  // RESET DASHBOARD COUNT
  resetDashboardData: () => ({
    type: actions.RESET_DASHBOARD_DATA,
  }),
  resetWalletData: () => ({
    type: actions.RESET_WALLET_DATA,
  }),
  resetWithHeldTransactionsData: () => ({
    type: actions.RESET_WITHHELD_TRANSACTIONS_DATA,
  }),
  //UPDATE THRESHOLD
  updateThreshold: (payload) => ({
    type: actions.UPDATE_THRESHOLD_AMOUNT,
    payload: payload,
  }),
  //FETCH LOOKUPS CURRENCY
  fetchLookupsCurrency: () => ({
    type: actions.FETCH_LOOKUPS_CURRENCY,
  }),
  //FETCH WALLET GROUPS
  fetchWalletGroups: () => ({
    type: actions.FETCH_WALLET_GROUPS,
  }),
  addCorpWallet: (payload) => ({
    type: actions.ADD_CORP_WALLET,
    payload: payload,
  }),
  fetchDailyBalance: (payload) => ({
    type: actions.FETCH_DAILY_BALANCE_DETAILS,
    payload: payload,
  }),
  resetBankDetails: () => ({
    type: actions.RESET_BANK_DETAILS_DATA,
  }),

  // FILTER TRANSACTIONS
  filterTransactionBody: (payload) => ({
    type: actions.FILTER_TRANSACTION_BODY,
    payload: payload,
  }),

  // FETCH SEARCH TRANSACTIONS DETAILS
  fetchSearchTransactionDetails: (payload) => ({
    type: actions.FETCH_SEARCH_TRANSACTION_DETAILS,
    payload: payload,
  }),
  //post credit transaction
  postCreditTransaction: (payload) => ({
    type: actions.POST_CREDIT_TRANSACTION,
    payload: payload,
  }),
  //post debit transaction
  postDebitTransaction: (payload) => ({
    type: actions.POST_DEBIT_TRANSACTION,
    payload: payload,
  }),
  //post transfer
  postTransferTransaction: (payload) => ({
    type: actions.POST_TRANSFER_TRANSACTION,
    payload: payload,
  }),
  //post settle transaction
  postSettleTransaction: (payload) => ({
    type: actions.POST_SETTLE_TRANSACTION,
    payload: payload,
  }),
  //post settle transaction
  postCancelTransaction: (payload) => ({
    type: actions.POST_CANCEL_TRANSACTION,
    payload: payload,
  }),
  resetCorpWallets: () => ({
    type: actions.RESET_CORP_WALLETS,
  }),
  // FETCH BANK ACCOUNTS
  fetchBankAccounts: () => ({
    type: actions.FETCH_BANK_ACCOUNTS,
  }),
  changeWalletStatus: (payload) => ({
    type: actions.CHANGE_WALLET_STATUS,
    payload: payload,
  }),

  // FETCH BANK ACCOUNT BY ACCT NO
  fetchBankAccountByAcctNo: (payload) => ({
    type: actions.FETCH_BANK_ACCOUNT_BY_ACCT_NO,
    payload: payload,
  }),

  // CRUD

  addBankAccount: (payload) => ({
    type: actions.ADD_BANK_ACCOUNT,
    payload: payload,
  }),

  editBankAccount: (payload) => ({
    type: actions.EDIT_BANK_ACCOUNT,
    payload: payload,
  }),

  deleteBankAccount: (payload) => ({
    type: actions.DELETE_BANK_ACCOUNT,
    payload: payload,
  }),

  deactivateBankAccount: (payload) => ({
    type: actions.DEACTIVATE_BANK_ACCOUNT,
    payload: payload,
  }),

  //FETCH LOOKUPS BANK
  fetchLookupsBank: () => ({
    type: actions.FETCH_LOOKUPS_BANK,
  }),

  // RESET BANK ACCOUNTS
  resetBankAccounts: () => ({
    type: actions.RESET_BANK_ACCOUNTS,
  }),

  resetEditBankAccounts: () => ({
    type: actions.RESET_EDIT_BANK_ACCOUNTS,
  }),

  resetDeleteBankAccounts: () => ({
    type: actions.RESET_DELETE_BANK_ACCOUNTS,
  }),

  resetDeactivateBankAccounts: () => ({
    type: actions.RESET_DEACTIVATE_BANK_ACCOUNTS,
  }),

  // FETCH SEARCH TRANSACTIONS DETAILS
  fetchSearchMerchantTransactionDetails: (payload) => ({
    type: actions.FETCH_SEARCH_MERCHANT_TRANSACTION_DETAILS,
    payload: payload,
  }),

  setIsFiltered: () => ({
    type: actions.SET_IS_FILTERED,
  }),

  manualCreditAdding: (payload) => ({
    type: actions.MANUAL_CREDIT,
    payload
  }),

  resetManualCredit: () => ({
    type: actions.RESET_MANUAL_CREDIT,
  }),

  generateManualCreditOtp: (payload) => ({
    type: actions.GENERATE_MANUAL_CREDIT_OTP,
    payload: payload,
  }),

  resetGenerateManualCreditOtp: () => ({
    type: actions.GENERATE_MANUAL_CREDIT_OTP_RESET,
  }),

  resendManualCreditOtp: (payload) => ({
    type: actions.RESEND_MANUAL_CREDIT_OTP,
    payload: payload,
  }),
  resetResendManualCreditOtp: () => ({
    type: actions.RESEND_MANUAL_CREDIT_OTP_RESET,
  }),

  newWallet: (payload) => ({
    type: actions.NEW_WALLET,
    payload,
  }),
  resetAddNewWalllet: () => ({
    type: actions.RESET_NEW_WALLET,
  }),
  newFeeWallet: (payload) => ({
    type: actions.NEW_FEE_WALLET,
    payload,
  }),
  resetAddNewFeeWalllet: () => ({
    type: actions.RESET_NEW_FEE_WALLET,
  }),
  fetchInstapayBanks: () => ({
    type: actions.FETCH_INSTAPAY_BANKS,
  }),
  cashOutOtp: (payload) => ({
    type: actions.GENERATE_CASHOUT_OTP,
    payload: payload
  }),
  resetCashoutOtp: () => ({
    type: actions.GENERATE_CASHOUT_OTP_RESET,
  }),
  resendCahoutOtp: (payload) => ({
    type: actions.RESEND_CASHOUT_OTP,
    payload: payload,
  }),
  resetResendCashoutOtp: () => ({
    type: actions.RESEND_CASHOUT_OTP_RESET,
  }),
  cashOut: (payload) => ({
    type: actions.CASH_OUT,
    payload
  }),
  resetCashOut: (payload) => ({
    type: actions.RESET_CASH_OUT,
    payload
  }),
  getCashoutTrxDetails: (payload) => ({
    type: actions.FETCH_CASHOUT_TRANSACTION_DETAILS,
    payload: payload,
  }),
  resetCashoutTrxDetails: () => ({
    type: actions.RESET_CASHOUT_TRANSACTION_DETAILS,
  }),

  // GET CASHIN TRANSACTION DETAILS
  getCashInTrxDetails: (payload) => ({
    type: actions.FETCH_CASHIN_TRANSACTION_DETAILS,
    payload: payload,
  }),

  // RESET CASHIN TRANSACTION DETAILS
  resetCashInTrxDetails: () => ({
    type: actions.RESET_CASHIN_TRANSACTION_DETAILS,
  }),

};
export default actions;





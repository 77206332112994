import actions from './actions';

const initState = {
  idToken: null,
  passport: null,
  keyGen: null,
  errorMessage: null,
  forgotMessage: null,
  modules: null,
  customModules: null,
  roles: null,
  isLoading: false,
  resetSuccess: false,
  identity: null,
  resetData: null,
  stores: null,
  secondTimer: 0,
  env: null,
  mqttToken: null,
  forbiddenRequest: false,
  kickedOut: false,
  customAuthorizations: null,
  disabledModules: ['CORPORATE_STORE_USER', 'CORPORATE_ADDRESS_BOOK'],
  disabledSubmodules: ['MERCHANT_BATCH_UPLOAD'],
  fetchingResetPassUrl: false,
  resetPassUrl: null,
  changingPassword: false,
  changedPasswordMessage: null,
  changePasswordSuccess: false,
  mqttOptions: null,
  generatingOtp: false,
  generateOtpSuccess: false,
  generateOtpMessage: null,
  generateOtpExpiration: null,
  isPasswordExp: false,
  fetchingGlyph: false,
  fetchedGlyph: null,
  hasResetTokenData: null,
  resettingTokenData: false,
  resetForgotPassword: false,
  resetForgotPasswordData: null,
  resettingForgotPassword: false,
  resetErrorMessage: null,
  resetError: false,
};

export default function authReducer(state = initState, action) {

  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      // const identity = action.identity && action.identity !== undefined && action.identity !== 'undefined' && action.identity !== 'null' ? JSON.parse(action.identity) : null;
      // const customModules = action.customModules && action.customModules !== undefined && action.customModules !== 'undefined' && action.customModules !== 'null' ? JSON.parse(action.customModules) : [];

      const customAuthorizations = action.customAuthorizations && action.customAuthorizations !== undefined && action.customAuthorizations !== 'undefined' && action.customAuthorizations !== 'null' ? JSON.parse(action.customAuthorizations) : [];
      const resetData = action.resetData && action.resetData !== undefined && action.resetData !== 'undefined' && action.resetData !== 'null' ? JSON.parse(action.resetData) : null;

      const mods = JSON.parse(action.modules).filter((el) => {
        const submods = el.submodules.filter((elB) => {
          return state.disabledSubmodules.indexOf(elB.alias) < 0;
        });
        el.submodules = submods;
        return state.disabledModules.indexOf(el.alias) < 0;
      });

      // remove POS_WALLET module
      const newMods = mods.filter(m => m.alias !== 'POS_WALLET');

      return {
        ...state,
        identity: JSON.parse(action.identity),
        idToken: action.token,
        passport: action.passport,
        keyGen: action.keyGen,
        modules: newMods,
        resetData: resetData,
        customAuthorizations: customAuthorizations,
        customModules: JSON.parse(action.customModules),
        errorMessage: null,
        generateOtpExpiration: null,
        country: action.country,
        kycProviders: action.kycProviders,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        kickedOut: false,
        errorMessage: action.error,
      };
    case actions.CLEAR_ERROR:
      return {
        ...state,
        errorMessage: null,
      };
    case actions.SET_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case actions.FORGOT_ERROR:
      return {
        ...state,
        forgotMessage: action.error,
      };
    case actions.SUBMODULES:
      const mods3 = action.payload.filter((el) => {
        const submods = el.submodules.filter((elB) => {
          return state.disabledSubmodules.indexOf(elB.alias) < 0;
        });
        el.submodules = submods;
        return state.disabledModules.indexOf(el.alias) < 0;
      });
      return {
        ...state,
        modules: mods3,
      };
    case actions.FORGOT_PASSWORD:
      return {
        ...state,
        emailData: action.emailData
      };
    case actions.FORGOT_PASSWORD_DONE:
      return {
        ...state,
        resetSuccess: action.success,
        forgotMessage: null,
        passport: action.passport && action.passport !== undefined ? action.passport : state.passport,
        keyGen: action.keyGen && action.keyGen !== undefined ? action.keyGen : state.keyGen,
      };
    case actions.RESET_SECOND_TIMER:
      return {
        ...state,
        secondTimer: action.secondTimer,
      };
    case actions.KICKED_OUT:
      return {
        ...state,
        kickedOut: true,
      };
    case actions.KICKED_OUT_CLEAR:
      return {
        ...state,
        kickedOut: false,
      };
    case actions.LOGIN_VALIDATOR:
      return {
        ...state,
        mqttToken: action.token,
      };
    case actions.LOGOUT:
      return {
        ...initState,
        kickedOut: state.kickedOut,
        errorMessage: state.errorMessage,
      };
    case actions.FETCHING_RESET_PASSWORD_URL:
      return {
        ...state,
        fetchingResetPassUrl: true,
      };
    case actions.FETCHED_RESET_PASSWORD_URL:
      return {
        ...state,
        fetchingResetPassUrl: false,
        resetPassUrl: action.resetPassUrl,
      };
    case actions.FETCH_RESET_PASSWORD_URL_FAILED:
      return {
        ...state,
        fetchingResetPassUrl: false,
        resetPassUrl: null,
      };
    case actions.CHANGING_PASSWORD:
      return {
        ...state,
        changingPassword: true
      };
    case actions.CHANGED_PASSWORD:
      return {
        ...state,
        changingPassword: false,
        changePasswordSuccess: true,
        changedPasswordMessage: null,
      };
    case actions.CHANGED_PASSWORD_FAILED:
      return {
        ...state,
        changingPassword: false,
        changedPasswordMessage: action.payload
      };
    case actions.RESET_CHANGE_PASSWORD:
      return {
        ...state,
        changingPassword: false,
        changePasswordSuccess: false,
        changedPasswordMessage: null,
      };
    case actions.SET_MQTT_OPTS:
      return {
        ...state,
        mqttOptions: action.options,
      };

    case actions.GETTING_TOKEN:
      return {
        ...state,
        gettingCsrfToken: true,
      };
    case actions.GET_TOKEN_SUCCESS:
      return {
        ...state,
        gettingCsrfToken: false,
        csrfToken: action.token,
      };
    case actions.GET_TOKEN_FAILED:
      return {
        ...state,
        gettingCsrfToken: false
      };
    case actions.GENERATING_LOGIN_OTP:
      return {
        ...state,
        generatingOtp: true,
        generateOtpExpiration: null,
      };
    case actions.GENERATED_LOGIN_OTP:
      return {
        ...state,
        generatingOtp: false,
        generateOtpSuccess: true,
        generateOtpMessage: action.message,
        generateOtpExpiration: action.expiration,
        passport: action.passport,
        keyGen: action.keyGen,
      };
    case actions.GENERATE_LOGIN_OTP_FAILED:
      return {
        ...state,
        generatingOtp: false,
        generateOtpSuccess: false,
        generateOtpMessage: action.message,
        generateOtpExpiration: null,
        errorMessage: action.error,
      };
    case actions.GENERATE_LOGIN_OTP_RESET:
      return {
        ...state,
        generatingOtp: false,
        generateOtpSuccess: false,
        generateOtpMessage: null,
      };
    case actions.RESENDING_LOGIN_OTP:
      return {
        ...state,
        resendingOtp: true,
        generateOtpExpiration: null,
      };
    case actions.RESEND_LOGIN_OTP_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        resendingOtp: false,
        resendOtpSuccess: true,
        resendOtpMessage: action.message,
        generateOtpExpiration: action.expiration,
        passport: action.passport,
        keyGen: action.keyGen,
      };
    case actions.RESEND_LOGIN_OTP_FAILED:
      return {
        ...state,
        resendingOtp: false,
        resendOtpSuccess: false,
        resendOtpMessage: action.message,
        generateOtpExpiration: null,
      };
    case actions.RESEND_LOGIN_OTP_RESET:
      return {
        ...state,
        resendingOtp: false,
        resendOtpSuccess: false,
        resendOtpMessage: null,
      };
    case actions.NEW_USER:
      return {
        ...state,
        tokenId: action.tokenId,
        isFirst: action.isFirst,
        isPasswordExp: action.isPasswordExp
      };
    case actions.FETCHING_GLYPH_INFO:
      return {
        ...state,
        fetchedGlyph: null,
        fetchingGlyph: true,
      }
    case actions.FETCHED_GLYPH_INFO_SUCCESS:
      return {
        ...state,
        fetchedGlyph: action.glyphInfo,
        fetchingGlyph: false,
      }
    case actions.FETCHED_GLYPH_INFO_FAILED:
      return {
        ...state,
        fetchedGlyph: null,
        fetchingGlyph: false,
      }
    case actions.RESETTING_FORGOT_PASSWORD:
      return {
        ...state,
        resettingForgotPassword: true,
      };
    case actions.RESET_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        resetForgotPasswordData: action.result,
        resetForgotPassword: true,
        resettingForgotPassword: false,
      };
    case actions.RESET_FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        resetErrorMessage: action.message,
        resetError: true,
        resettingForgotPassword: false,
        resetForgotPassword: false,
      };
    case actions.RESETTING_TOKEN_DATA:
      return {
        ...state,
        resettingTokenData: true,
      };
    case actions.RESET_TOKEN_DATA_SUCCESS:
      return {
        ...state,
        resettingTokenData: false,
        hasResetTokenData: action.result,
      };
    case actions.RESET_TOKEN_DATA_FAILED:
      return {
        ...state,
        resettingTokenData: false,
        hasResetTokenData: null,
      };
    case actions.RESET_ERROR_MESSAGE:
      return {
        ...state,
        resetErrorMessage: action.payload,
        resetError: action.isError,
      };
    default:
      return state;
  }
}

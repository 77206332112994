import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/ForgotPassword/ForgotPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      // import('@iso/containers/RedirectHandler/RedirectHandler')
      import('@iso/containers/Redirects/ResetPassword/RedirectResetPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.REDIRECT_RESET_SUCCESS,
    component: lazy(() =>
      import('@iso/containers/Redirects/ResetSuccess/RedirectResetSuccess')
    ),
  },
  {
    path: PUBLIC_ROUTE.REDIRECT_RESET_USED,
    component: lazy(() =>
      import('@iso/containers/Redirects/ResetUsed/RedirectResetUsed')
    ),
  },
  {
    path: PUBLIC_ROUTE.REDIRECT_RESET_EXPIRED,
    component: lazy(() =>
      import('@iso/containers/Redirects/ResetExpired/RedirectResetExpired')
    ),
  }
];

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.idToken);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: location },
              }}
            />
          )
      }
    />
  );
}

if (publicRoutes.find(x => x.path === window.location.pathname) === undefined && !window.location.pathname.includes('/portal')) {
  publicRoutes.push({
    path: window.location.pathname,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  })
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {
              publicRoutes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact}>
                  <route.component />
                </Route>
              ))
            }
            <PrivateRoute path="/portal">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}

const actions = {
    // SAMPLE FETCH
    FETCH_SAMPLE: 'FETCH_SAMPLE',
    FETCHING_SAMPLE: 'FETCHING_SAMPLE',
    FETCHED_SAMPLE: 'FETCHED_SAMPLE',
    FETCH_SAMPLE_FAILED: 'FETCH_SAMPLE_FAILED',

    //SEARCH AUDITLOGS
    SEARCH_AUDITLOGS: 'SEARCH_AUDITLOGS',
    SEARCHING_AUDITLOGS: 'SEARCHING_AUDITLOGS',
    SEARCHED_AUDITLOGS: 'SEARCHED_AUDITLOGS',
    SEARCH_AUDITLOGS_FAILED: 'SEARCH_AUDITLOGS_FAILED',
    RESET_AUDITLOGS: 'RESET_AUDITLOGS',
    // ROLE FETCH
    FETCH_ROLE: 'FETCH_ROLE',
    FETCHING_ROLE: 'FETCHING_ROLE',
    FETCHED_ROLE: 'FETCHED_ROLE',
    FETCH_ROLE_FAILED: 'FETCH_ROLE_FAILED',

    // ADD AUTH ROLE
    ADD_AUTH_ROLES: 'ADD_AUTH_ROLES',
    ADDING_AUTH_ROLES: 'ADDING_AUTH_ROLES',
    AUTH_ROLES_ADDED: 'AUTH_ROLES_ADDED',
    ADD_AUTH_ROLES_FAILED: 'ADD_AUTH_ROLES_FAILED',
    RESET_ADD_AUTH_ROLES: 'RESET_ADD_AUTH_ROLES',

    // FETCH PERMISSION
    FETCH_PERMISSION: 'FETCH_PERMISSION',
    FETCHING_PERMISSION: 'FETCHING_PERMISSION',
    ROLE_PERMISSION_FETCHED: 'ROLE_PERMISSION_FETCHED',
    FETCH_PERMISSION_FAILED: 'FETCH_PERMISSION_FAILED',
    OPEN_PERMISSION_DIALOG: 'OPEN_PERMISSION_DIALOG',
    RESET_PERMISSION_DATA: 'RESET_PERMISSION_DATA',

    // FETCH FILTERED ROLE IN USER PORTAL
    FETCH_STATUS: 'FETCH_STATUS',
    FETCHING_STATUS: 'FETCHING_STATUS',
    STATUS_FETCHED: 'STATUS_FETCHED',
    FETCH_STATUS_FAILED: 'FETCH_STATUS_FAILED',

    // SAVE AUTH MANAGEMENT
    SAVE_AUTH_ROLE_MANAGEMENT: 'SAVE_AUTH_ROLE_MANAGEMENT',
    SAVING_AUTH_ROLE_MANAGEMENT: 'SAVING_AUTH_ROLE_MANAGEMENT',
    AUTH_ROLE_MANAGEMENT_SAVED: 'AUTH_ROLE_MANAGEMENT_SAVED',
    SAVE_AUTH_ROLE_MANAGEMENT_FAILED: 'SAVE_AUTH_ROLE_MANAGEMENT_FAILED',
    RESET_SAVE_AUTH_ROLE_MANAGEMENT: 'RESET_SAVE_AUTH_ROLE_MANAGEMENT',

    //FETCH LOOKUPS
    FETCH_LOOKUPS: 'FETCH_LOOKUPS',
    FETCHING_LOOKUPS: 'FETCHING_LOOKUPS',
    FETCHED_LOOKUPS: 'FETCHED_LOOKUPS',
    FETCH_LOOKUPS_FAILED: 'FETCH_LOOKUPS_FAILED',
    FETCH_LOOKUPS_ERROR: 'FETCH_LOOKUPS_ERROR',
    RESET_LOOKUPS: 'RESET_LOOKUPS',

    //
    FETCH_LOOKUPS_STATUS: 'FETCH_LOOKUPS_STATUS',
    FETCHING_LOOKUPS_STATUS: 'FETCHING_LOOKUPS_STATUS',
    FETCHED_LOOKUPS_STATUS: 'FETCHED_LOOKUPS_STATUS',
    FETCH_LOOKUPS_STATUS_FAILED: 'FETCH_LOOKUPS_STATUS_FAILED',

    //ADD LOOKUPS
    ADD_LOOKUPS: 'ADD_LOOKUPS',
    ADDING_LOOKUPS: 'ADDING_LOOKUPS',
    // LOADING_MORE_LOGS: 'LOADING_MORE_LOGS',
    ADDED_LOOKUPS: 'ADDED_LOOKUPS',
    ADD_LOOKUPS_FAILED: 'ADD_LOOKUPS_FAILED',
    ADD_LOOKUPS_ERROR: 'ADD_LOOKUPS_ERROR',
    RESET_ADD_LOOKUPS: 'RESET_ADD_LOOKUPS',

    //DELETE LOOKUPS
    DELETE_LOOKUPS: 'DELETE_LOOKUPS',
    DELETING_LOOKUPS: 'DELETING_LOOKUPS',
    // LOADING_MORE_LOGS: 'LOADING_MORE_LOGS',
    DELETED_LOOKUPS: 'DELETED_LOOKUPS',
    DELETE_LOOKUPS_FAILED: 'DELETE_LOOKUPS_FAILED',
    DELETE_LOOKUPS_ERROR: 'DELETE_LOOKUPS_ERROR',
    RESET_DELETE_LOOKUPS: 'RESET_DELETE_LOOKUPS',

    //UPDATE LOOKUPS
    UPDATE_LOOKUPS: 'UPDATE_LOOKUPS',
    UPDATING_LOOKUPS: 'UPDATING_LOOKUPS',
    // LOADING_MORE_LOGS: 'LOADING_MORE_LOGS',
    UPDATED_LOOKUPS: 'UPDATED_LOOKUPS',
    UPDATE_LOOKUPS_FAILED: 'UPDATE_LOOKUPS_FAILED',
    UPDATE_LOOKUPS_ERROR: 'UPDATE_LOOKUPS_ERROR',
    RESET_UPDATE_LOOKUPS: 'RESET_UPDATE_LOOKUPS',

    //FETCH RESPONSE CODES
    FETCH_RESPONSE_CODE: 'FETCH_RESPONSE_CODE',
    FETCHING_RESPONSE_CODE: 'FETCHING_RESPONSE_CODE',
    LOADING_MORE_RESPONSE_CODE: 'LOADING_MORE_RESPONSE_CODE',
    FETCHED_RESPONSE_CODE: 'FETCHED_RESPONSE_CODE',
    FETCH_RESPONSE_CODE_FAILED: 'FETCH_RESPONSE_CODE_FAILED',
    FETCH_RESPONSE_CODE_ERROR: 'FETCH_RESPONSE_CODE_ERROR',
    RESET_RESPONSE_CODE: 'RESET_RESPONSE_CODE',

    //ADD RESPONSE CODES
    ADD_RESPONSE_CODE: 'ADD_RESPONSE_CODE',
    ADDING_RESPONSE_CODE: 'ADDING_RESPONSE_CODE',
    // LOADING_MORE_RESPONSE_CODE: 'LOADING_MORE_RESPONSE_CODE',
    ADDED_RESPONSE_CODE: 'ADDED_RESPONSE_CODE',
    ADD_RESPONSE_CODE_FAILED: 'ADD_RESPONSE_CODE_FAILED',
    ADD_RESPONSE_CODE_ERROR: 'ADD_RESPONSE_CODE_ERROR',
    RESET_ADD_RESPONSE_CODE: 'RESET_ADD_RESPONSE_CODE',
    RESET_ADD_RESPONSE_CODE_MESSAGE: 'RESET_ADD_RESPONSE_CODE_MESSAGE',

    //UPDATE RESPONSE CODES
    UPDATE_RESPONSE_CODE: 'UPDATE_RESPONSE_CODE',
    UPDATING_RESPONSE_CODE: 'UPDATING_RESPONSE_CODE',
    // LOADING_MORE_RESPONSE_CODE: 'LOADING_MORE_RESPONSE_CODE',
    UPDATED_RESPONSE_CODE: 'UPDATED_RESPONSE_CODE',
    UPDATE_RESPONSE_CODE_FAILED: 'UPDATE_RESPONSE_CODE_FAILED',
    UPDATE_RESPONSE_CODE_ERROR: 'UPDATE_RESPONSE_CODE_ERROR',
    RESET_UPDATE_RESPONSE_CODE: 'RESET_UPDATE_RESPONSE_CODE',
    RESET_UPDATE_RESPONSE_CODE_MESSAGE: 'RESET_UPDATE_RESPONSE_CODE_MESSAGE',

    //DELETE RESPONSE CODES
    DELETE_RESPONSE_CODE: 'DELETE_RESPONSE_CODE',
    DELETING_RESPONSE_CODE: 'DELETING_RESPONSE_CODE',
    // LOADING_MORE_RESPONSE_CODE: 'LOADING_MORE_RESPONSE_CODE',
    DELETED_RESPONSE_CODE: 'DELETED_RESPONSE_CODE',
    DELETE_RESPONSE_CODE_FAILED: 'DELETE_RESPONSE_CODE_FAILED',
    DELETE_RESPONSE_CODE_ERROR: 'DELETE_RESPONSE_CODE_ERROR',
    RESET_DELETE_RESPONSE_CODE: 'RESET_DELETE_RESPONSE_CODE',


    // FETCH MERCHANTS FOR USER MANAGEMENT
    FETCH_MERCHANT_LIST_SETTINGS: 'FETCH_MERCHANT_LIST_SETTINGS',
    FETCHING_MERCHANT_LIST_SETTINGS: 'FETCHING_MERCHANT_LIST_SETTINGS',
    FETCHED_MERCHANT_LIST_SETTINGS: 'FETCHED_MERCHANT_LIST_SETTINGS',
    FETCH_MERCHANT_LIST_SETTINGS_FAILED: 'FETCH_MERCHANT_LIST_SETTINGS_FAILED',

    // FETCH MERCHANTS FOR USER MANAGEMENT
    FETCH_NEW_MERCHANT_LIST: 'FETCH_NEW_MERCHANT_LIST',
    FETCHING_NEW_MERCHANT_LIST: 'FETCHING_NEW_MERCHANT_LIST',
    FETCHED_NEW_MERCHANT_LIST: 'FETCHED_NEW_MERCHANT_LIST',
    FETCH_NEW_MERCHANT_LIST_FAILED: 'FETCH_NEW_MERCHANT_LIST_FAILED',

    // FETCH USERS FOR USER MANAGEMENT
    FETCH_USERS: 'FETCH_USERS',
    FETCHING_USERS: 'FETCHING_USERS',
    FETCHED_USERS: 'FETCHED_USERS',
    FETCH_USERS_FAILED: 'FETCH_USERS_FAILED',
    FETCH_USERS_RESET: 'FETCH_USERS_RESET',

      // FETCH TENANTS
      FETCH_TENANTS: 'FETCH_TENANTS',
      FETCHING_TENANTS: 'FETCHING_TENANTS',
      FETCHED_TENANTS: 'FETCHED_TENANTS',
      FETCH_TENANTS_FAILED: 'FETCH_TENANTS_FAILED',
      FETCH_TENANTS_RESET: 'FETCH_TENANTS_RESET',

    // FETCH USERNAMES FOR USER MANAGEMENT
    FETCH_USERNAMES: 'FETCH_USERNAMES',
    FETCHING_USERNAMES: 'FETCHING_USERNAMES',
    FETCHED_USERNAMES: 'FETCHED_USERNAMES',
    FETCH_USERNAMES_FAILED: 'FETCH_USERNAMES_FAILED',
    RESET_USERNAMES: 'RESET_USERNAMES',

    // ADD USER 
    ADD_USER: 'ADD_USER',
    ADDING_USER: 'ADDING_USER',
    ADDED_USER: 'ADDED_USER',
    RESET_ADD_USER: 'RESET_ADD_USER',
    ADD_USER_FAILED: 'ADD_USER_FAILED',

    // ADD TENANT FROM BIS
    ADD_TENANT: 'ADD_TENANT',
    ADDING_TENANT: 'ADDING_TENANT',
    ADDED_TENANT: 'ADDED_TENANT',
    RESET_ADD_TENANT: 'RESET_ADD_TENANT',
    ADD_TENANT_FAILED: 'ADD_TENANT_FAILED',

    // DELETE USER
    DELETE_USER: 'DELETE_USER',
    DELETING_USER: 'DELETING_USER',
    DELETED_USER: 'DELETED_USER',
    RESET_DELETE_USER: 'RESET_DELETE_USER',
    DELETE_USER_FAILED: 'DELETE_USER_FAILED',

    // EDIT USER
    EDIT_USER: 'EDIT_USER',
    EDITING_USER: 'EDITING_USER',
    EDITED_USER: 'EDITED_USER',
    RESET_EDIT_USER: 'RESET_EDIT_USER',
    EDIT_USER_FAILED: 'EDIT_USER_FAILED',

    // SEARCH MERCHANTS
    SETTINGS_SEARCH_MERCHANTS: 'SETTINGS_SEARCH_MERCHANTS',
    SETTINGS_SEARCHING_MERCHANTS_FAILED: 'SETTINGS_SEARCHING_MERCHANTS_FAILED',
    SETTINGS_SEARCHING_MERCHANTS: 'SETTINGS_SEARCHING_MERCHANTS',
    SETTINGS_SEARCHED_MERCHANTS: 'SETTINGS_SEARCHED_MERCHANTS',

    // FETCH MERCHANT SETTINGS
    SETTINGS_FETCH_MERCHANT: 'SETTINGS_FETCH_MERCHANT',
    SETTINGS_FETCHING_MERCHANT: 'SETTINGS_FETCHING_MERCHANT',
    SETTINGS_FETCHED_MERCHANT: 'SETTINGS_FETCHED_MERCHANT',
    SETTINGS_FETCH_MERCHANT_FAILED: 'SETTINGS_FETCH_MERCHANT_FAILED',
    SETTINGS_FETCH_MERCHANT_RESET: 'SETTINGS_FETCH_MERCHANT_RESET',

    // FETCH MERCHANT CONFIGURATIONS
    FETCH_MERCHANT_CONFIGURATIONS: 'FETCH_MERCHANT_CONFIGURATIONS',
    FETCHING_MERCHANT_CONFIGURATIONS: 'FETCHING_MERCHANT_CONFIGURATIONS',
    FETCHED_MERCHANT_CONFIGURATIONS: 'FETCHED_MERCHANT_CONFIGURATIONS',
    FETCH_MERCHANT_CONFIGURATIONS_FAILED: 'FETCH_MERCHANT_CONFIGURATIONS_FAILED',
    FETCH_MERCHANT_CONFIGURATIONS_RESET: 'FETCH_MERCHANT_CONFIGURATIONS_RESET',

    // ADD MERCHANT SETTINGS
    ADD_MERCHANT_SETTINGS: 'ADD_MERCHANT_SETTINGS',
    ADDING_MERCHANT_SETTINGS: 'ADDING_MERCHANT_SETTINGS',
    ADDED_MERCHANT_SETTINGS: 'ADDED_MERCHANT_SETTINGS',
    RESET_ADD_MERCHANT_SETTINGS: 'RESET_ADD_MERCHANT_SETTINGS',
    ADD_MERCHANT_SETTINGS_FAILED: 'ADD_MERCHANT_SETTINGS_FAILED',

    // EDIT MERCHANT SETTINGS
    EDIT_MERCHANT_SETTINGS: 'EDIT_MERCHANT_SETTINGS',
    EDITING_MERCHANT_SETTINGS: 'EDITING_MERCHANT_SETTINGS',
    EDITED_MERCHANT_SETTINGS: 'EDITED_MERCHANT_SETTINGS',
    RESET_EDIT_MERCHANT_SETTINGS: 'RESET_EDIT_MERCHANT_SETTINGS',
    EDIT_MERCHANT_SETTINGS_FAILED: 'EDIT_MERCHANT_SETTINGS_FAILED',

    // DELETE USER
    DELETE_MERCHANT_SETTINGS: 'DELETE_MERCHANT_SETTINGS',
    DELETING_MERCHANT_SETTINGS: 'DELETING_MERCHANT_SETTINGS',
    DELETED_MERCHANT_SETTINGS: 'DELETED_MERCHANT_SETTINGS',
    RESET_DELETE_MERCHANT_SETTINGS: 'RESET_DELETE_MERCHANT_SETTINGS',
    DELETE_MERCHANT_SETTINGS_FAILED: 'DELETE_MERCHANT_SETTINGS_FAILED',

    // RESET USER IN USER MANAGEMENT
    RESET_MERCHANT_USER_PASSWORD: 'RESET_MERCHANT_USER_PASSWORD',
    RESETTING_MERCHANT_USER_PASSWORD: 'RESETTING_MERCHANT_USER_PASSWORD',
    MERCHANT_USER_PASSWORD_RESET_SUCCESS: 'MERCHANT_USER_PASSWORD_RESET_SUCCESS',
    MERCHANT_USER_PASSWORD_RESET_FAILED: 'MERCHANT_USER_PASSWORD_RESET_FAILED',
    MERCHANT_USER_PASSWORD_RESET_ERROR: 'MERCHANT_USER_PASSWORD_RESET_ERROR',
    RESET_MERCHANT_USER_PASSWORD_RESET: 'RESET_MERCHANT_USER_PASSWORD_RESET',

    getSampleList: () => ({
        type: actions.FETCH_SAMPLE,
    }),

    searchAuditLogs: (payload) => ({
        type: actions.SEARCH_AUDITLOGS,
        payload: payload,
    }),
    resetAuditLogs: () => ({
        type: actions.RESET_AUDITLOGS,
    }),
    getRoles: () => ({
        type: actions.FETCH_ROLE,
    }),
    addAuthRole: (payload) => ({
        type: actions.ADD_AUTH_ROLES,
        payload: payload,
    }),
    resetAddAuthRole: () => ({
        type: actions.RESET_ADD_AUTH_ROLES,
    }),
    fetchPermission: (payload) => ({
        type: actions.FETCH_PERMISSION,
        payload,
    }),
    toggleRolePermissionDialog: () => ({
        type: actions.OPEN_PERMISSION_DIALOG,
    }),
    resetRolePermissionData: () => ({
        type: actions.RESET_PERMISSION_DATA,
    }),
    fetchStatus: () => ({
        type: actions.FETCH_STATUS,
    }),
    saveAuthManagement: (payload) => ({
        type: actions.SAVE_AUTH_ROLE_MANAGEMENT,
        payload: payload,
    }),
    resetSaveAuthManagement: () => ({
        type: actions.RESET_SAVE_AUTH_ROLE_MANAGEMENT,
    }),
    // LOOKUPS
    fetchLookups: () => ({
        type: actions.FETCH_LOOKUPS,
    }),
    addLookups: (payload) => ({
        type: actions.ADD_LOOKUPS,
        payload
    }),
    resetAddLookups: () => ({
        type: actions.RESET_ADD_LOOKUPS,
    }),

    deleteLookups: (payload) => ({
        type: actions.DELETE_LOOKUPS,
        payload,
    }),
    resetDeleteLookups: () => ({
        type: actions.RESET_DELETE_LOOKUPS,
    }),

    updateLookups: (payload) => ({
        type: actions.UPDATE_LOOKUPS,
        payload,
    }),
    resetUpdateLookups: () => ({
        type: actions.RESET_UPDATE_LOOKUPS,
    }),
    getStatusLookups: (payload) => ({
        type: actions.FETCH_LOOKUPS_STATUS,
        payload: payload,
    }),
    // RESPONSE CODE
    fetchResponseCode: (payload) => ({
        type: actions.FETCH_RESPONSE_CODE,
        payload
    }),
    addResponseCode: (payload) => ({
        type: actions.ADD_RESPONSE_CODE,
        payload
    }),
    updateResponseCode: (payload) => ({
        type: actions.UPDATE_RESPONSE_CODE,
        payload
    }),
    deleteResponseCode: (payload) => ({
        type: actions.DELETE_RESPONSE_CODE,
        payload
    }),
    addResponseCodeReset: (payload) => ({
        type: actions.RESET_ADD_RESPONSE_CODE,
        payload
    }),
    addResponseCodeResetMessage: (payload) => ({
        type: actions.RESET_ADD_RESPONSE_CODE_MESSAGE,
        payload
    }),
    updateResponseCodeReset: (payload) => ({
        type: actions.RESET_UPDATE_RESPONSE_CODE,
        payload
    }),
    updateResponseCodeResetMessage: (payload) => ({
        type: actions.RESET_UPDATE_RESPONSE_CODE_MESSAGE,
        payload
    }),
    resetDeleteResponseCode: (payload) => ({
        type: actions.RESET_DELETE_RESPONSE_CODE,
        payload
    }),
    // FETCH MERCHANTS FOR USER MANAGEMENT
    fetchMerchantsSettings: () => ({
        type: actions.FETCH_MERCHANT_LIST_SETTINGS,
    }),

    fetchMerchantsNew: () => ({
        type: actions.FETCH_NEW_MERCHANT_LIST,
    }),
    // SEARCH MERCHANT
    searchMerchantSettings: (payload) => ({
        type: actions.SETTINGS_SEARCH_MERCHANTS,
        payload,
    }),
    // FETCH MERCHANT SETTINGS
    fetchMerchantSettings: (payload) => ({
        type: actions.SETTINGS_FETCH_MERCHANT,
        payload,
    }),

    resetFetchMerchantSettings: () => ({
        type: actions.SETTINGS_FETCH_MERCHANT_RESET,
    }),

    // ADD MERCHANT SETTINGS
    addMerchantSettings: (payload) => ({
        type: actions.ADD_MERCHANT_SETTINGS,
        payload,
    }),

    resetAddMerchantSettings: () => ({
        type: actions.RESET_ADD_MERCHANT_SETTINGS,
    }),

    // EDIT MERCHANT SETTINGS
    editMerchantSettings: (payload) => ({
        type: actions.EDIT_MERCHANT_SETTINGS,
        payload,
    }),

    resetEditMerchantSettings: () => ({
        type: actions.RESET_EDIT_MERCHANT_SETTINGS,
    }),

    // DELETE MERCHANT SETTING
    deleteMerchantSetting: (payload) => ({
        type: actions.DELETE_MERCHANT_SETTINGS,
        payload,
    }),

    resetDeleteMerchantSetting: () => ({
        type: actions.RESET_DELETE_MERCHANT_SETTINGS,
    }),

    addPushToElastic: (payload) => ({
        type: actions.ADD_TO_ELASTIC,
        payload,
    }),

    resetAddPushToElastic: () => ({
        type: actions.RESET_ADD_TO_ELASTIC,
    }),

    // viewElasticCard: () => ({
    //   type: actions.VIEW_CARD_ELASTIC,
    // }),


    // FETCH MERCHANT SETTINGS
    fetchMerchantConfigurations: (payload) => ({
        type: actions.FETCH_MERCHANT_CONFIGURATIONS,
        payload,
    }),

    resetFetchMerchantConfigurations: () => ({
        type: actions.FETCH_MERCHANT_CONFIGURATIONS_RESET,
    }),

    // FETCH USERS FOR USER MANAGEMENT
    fetchUsers: (payload) => ({
        type: actions.FETCH_USERS,
        payload,
    }),
    fetchTenants: (payload) => ({
        type: actions.FETCH_TENANTS,
        payload,
    }),
    addUser: (payload) => ({
        type: actions.ADD_USER,
        payload,
    }),
    addTenant: (payload) => ({
        type: actions.ADD_TENANT,
        payload,
    }),
    resetAddUser: () => ({
        type: actions.RESET_ADD_USER,
    }),
    resetAddTenant: () => ({
        type: actions.RESET_ADD_TENANT,
    }),
    editUser: (payload) => ({
        type: actions.EDIT_USER,
        payload,
    }),
    resetEditUser: () => ({
        type: actions.RESET_EDIT_USER,
    }),
    deleteUser: (payload) => ({
        type: actions.DELETE_USER,
        payload,
    }),
    resetDeleteUser: () => ({
        type: actions.RESET_DELETE_USER,
    }),
    resetPassword: (payload) => ({
        type: actions.RESET_MERCHANT_USER_PASSWORD,
        payload: payload,
    }),
    resetPasswordReset: () => ({
        type: actions.RESET_MERCHANT_USER_PASSWORD_RESET,
    }),
};
export default actions; 